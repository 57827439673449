import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import sortBy from 'lodash/sortBy'
import GeneralRedux, { GeneralTypes } from '../redux/GeneralRedux'
import { generalErrorHandler } from './EpicHelpers'

export default class GeneralEpic {
  static getAllServices = (api: any) => (action$: any) =>
    action$.pipe(
      ofType(GeneralTypes.GET_ALL_SERVICES_REQUEST),
      switchMap(() =>
        from(api.getAllServices()).pipe(
          mergeMap((response: any) => of(GeneralRedux.onChangeGeneralValue('services', sortBy(response.data, 'id')))),
          catchError(generalErrorHandler()),
        ),
      ),
    )

  static getAllActions = (apis: { next: Object; api: Object }) => [GeneralEpic.getAllServices(apis.api)]
}
