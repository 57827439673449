// see https://github.com/growthbook/examples/tree/main/next-js-pages
// for various ways to use the library on next.js pages

import { Experiment, GrowthBookProvider, Result, GrowthBook } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { useApis } from '../contexts/api-context'
import {
  addExperimentSessionData,
  getGrowthBookDeviceId,
  parseGrowthbookExperimentData,
  trackExperiment,
} from '../utilities/growthBookUtils'
import { useSession } from '@/src/contexts/session-context'

const gb = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  // Enable easier debugging of feature flags during development
  enableDevMode: process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_COUNTRY_IDENTIFIER === 'test',
  attributes: {
    // Add any attributes you want to be available in the GrowthBook UI
    // when creating experiments
    country: process.env.NEXT_PUBLIC_COUNTRY_IDENTIFIER,
  },
})

// Let the GrowthBook instance know when the URL changes so the active
// experiments can update accordingly
function updateGrowthBookURL() {
  gb.setURL(window.location.href)
}

const GrowthBookCustomProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter()
  const { api } = useApis()
  const { isLoggedIn } = useSession()
  const isLoggedInLive = useRef<boolean>(isLoggedIn)
  isLoggedInLive.current = isLoggedIn

  useEffect(() => {
    const onExperimentViewed = (experiment: Experiment<any>, result: Result<any>) => {
      const { experimentId, experimentVersion } = parseGrowthbookExperimentData(experiment, result)

      if (isLoggedInLive.current) {
        trackExperiment(api, experimentId, experimentVersion)
      } else {
        addExperimentSessionData({ experimentId, experimentVersion })
      }

      console.log('Viewed Experiment', {
        experimentId,
        experimentVersion,
      })
    }

    // Load features from the GrowthBook API and keep them up-to-date
    gb.init()

    gb.setAttributes({ deviceId: getGrowthBookDeviceId() })
    gb.setTrackingCallback(onExperimentViewed)

    // Subscribe to route change events and update GrowthBook
    router.events.on('routeChangeComplete', updateGrowthBookURL)
    return () => router.events.off('routeChangeComplete', updateGrowthBookURL)
  }, [])

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>
}

export default GrowthBookCustomProvider
