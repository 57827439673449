import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { generalErrorHandler, generalSuccessHandler } from './EpicHelpers'
import TherapistActions, { TherapistTypes } from '../redux/TherapistRedux'
import UserActions, { USER_TYPE } from '../redux/UserRedux'
import GeneralActions from '../redux/GeneralRedux'
import type { AppointmentType } from '../types/TherapistStateType'
import type { ReduxState } from '../store/reducers'
import { ApiEndpoints } from '../services/api'

export default class TherapistEpic {
  static getAllClients = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.GET_ALL_CLIENTS_REQUEST),
      switchMap(() =>
        from(api.getAllClients()).pipe(
          mergeMap((response: any) =>
            from([
              GeneralActions.onStopFetching(['clients']),
              TherapistActions.onChangeTherapistValue('clients', response.data),
            ]),
          ),
          catchError(generalErrorHandler('clients')),
        ),
      ),
    )
  static addSchedule = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.ADD_SCHEDULE_REQUEST),
      switchMap(({ data }: { data: AppointmentType }) =>
        from(api.addSchedule(data)).pipe(
          switchMap(() => of(TherapistActions.getScheduleRequest())),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  static editSchedule = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.EDIT_SCHEDULE_REQUEST),
      switchMap(({ id, data }: { id: number; data: AppointmentType }) =>
        from(api.editSchedule(id, data)).pipe(
          switchMap(() =>
            from([
              generalSuccessHandler('component.toaster.editScheduleSuccess'),
              TherapistActions.getScheduleRequest(),
            ]),
          ),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  static getTherapistData = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.GET_THERAPIST_DATA_REQUEST),
      switchMap(({ therapistUserId }: { therapistUserId: number }) =>
        from(api.getTherapistData(therapistUserId)).pipe(
          switchMap((response: any) => of(TherapistActions.onMergeTherapistValue(response.data))),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  static getSchedule = (api: ApiEndpoints) => (action$: any, state$: { value: ReduxState }) =>
    action$.pipe(
      ofType(TherapistTypes.GET_SCHEDULE_REQUEST),
      mergeMap(() =>
        from(api.getSchedule()).pipe(
          switchMap(({ data }: { data: AppointmentType[] }) => {
            const actions = [
              generalSuccessHandler('', 'therapistSchedule'),
              TherapistActions.onChangeTherapistValue('schedule', data),
            ]
            if (
              Array.isArray(state$.value.therapist.prebookingAppointments) &&
              state$.value.therapist.prebookingAppointments.length > 0
            ) {
              actions.push(TherapistActions.onChangeTherapistValue('prebookingAppointments', []))
            }
            return from(actions)
          }),
          catchError(generalErrorHandler('therapistSchedule')),
        ),
      ),
    )
  static getScheduleAsClient = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.GET_SCHEDULE_AS_CLIENT_REQUEST),
      switchMap(({ therapistId, dateTo, serviceId }) =>
        from(api.getSchedule(therapistId, dateTo, serviceId)).pipe(
          switchMap(({ data }: { data: AppointmentType[] }) => {
            const actions = [
              generalSuccessHandler('', 'therapistSchedule'),
              TherapistActions.onChangeTherapistValue('schedule', data),
            ]
            return from(actions)
          }),
          catchError(generalErrorHandler('therapistSchedule')),
        ),
      ),
    )
  static deleteSchedule = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.DELETE_SCHEDULE_REQUEST),
      switchMap(({ id }: { id: number }) =>
        from(api.deleteSchedule(id)).pipe(
          switchMap(() =>
            from([
              generalSuccessHandler('component.toaster.deleteScheduleSuccess'),
              TherapistActions.getScheduleRequest(),
            ]),
          ),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  static updateTherapistData = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.UPDATE_THERAPIST_DATA_REQUEST),
      switchMap(({ data }) =>
        from(api.updateTherapistData(data)).pipe(
          mergeMap(() =>
            from([
              generalSuccessHandler('component.toaster.therapistDataUpdateSuccess', 'updateTherapistData'),
              TherapistActions.onMergeTherapistValue(data),
            ]),
          ),
          catchError(generalErrorHandler('updateTherapistData')),
        ),
      ),
    )
  static therapistReserveAppointment = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(TherapistTypes.RESERVE_PREBOOKING_APPOINTMENT_REQUEST),
      switchMap(
        ({
          appointments,
          emailNote,
          videoChat,
        }: {
          appointments: AppointmentType[]
          emailNote?: string
          videoChat?: boolean
        }) =>
          from(api.reserveAppointment({ events: appointments, emailNote, videoChat })).pipe(
            mergeMap(() =>
              from([
                generalSuccessHandler('component.toaster.prebookingCreatedSuccessful', 'reservePrebookingAppointment'),
                TherapistActions.onMergeTherapistValue({ prebookingAppointments: [] }),
                UserActions.getAllAppointmentsRequest(USER_TYPE.THERAPIST),
              ]),
            ),
            catchError(generalErrorHandler('reservePrebookingAppointment')),
          ),
      ),
    )

  static getAllActions = ({ api }: { api: ApiEndpoints }) => [
    TherapistEpic.getAllClients(api),
    TherapistEpic.addSchedule(api),
    TherapistEpic.editSchedule(api),
    TherapistEpic.deleteSchedule(api),
    TherapistEpic.getSchedule(api),
    TherapistEpic.getScheduleAsClient(api),
    TherapistEpic.getTherapistData(api),
    TherapistEpic.updateTherapistData(api),
    TherapistEpic.therapistReserveAppointment(api),
  ]
}
