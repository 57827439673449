import React from 'react'
import ToastProvider from './ToastProvider'

type Props = { children: React.ReactNode }

const ProvidersCommon: React.FC<Props> = ({ children }) => {
  return (
    <>
      <ToastProvider />
      {children}
    </>
  )
}

export default ProvidersCommon
