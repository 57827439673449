import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import createStore from '../src/store/createStore'
import withReduxAndAxios from '../src/services/withReduxAndAxios'
import createApis from '../src/store/createApis'
import Toaster from '../src/utilities/toaster'
import '../src/styles/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datetime/css/react-datetime.css'
import { PageCtxType } from '../src/types/NextJsTypes'
import type { AppProps } from 'next/app'
import { NextComponentType, NextPageContext } from 'next'
import { GTM_ID, pageview } from '../src/analytics/gtm'
import Auth, { AuthType } from '../src/components/Auth'
import HeadCommonTags from '../src/components/head/HeadCommon'
import ProvidersCommonPageRouter from '../src/components/ProvidersCommonPageRouter'
import ProvidersAuthenticated from '../src/components/ProvidersAuthenticated'
import useScrollPosition from '../src/hooks/useScrollPosition'
import useIsAppWebView from '../src/hooks/useIsAppWebView'
import FetchCommonIntoRedux from '../src/components/FetchCommonIntoRedux'
import { getTranslate } from 'src/utilities/i18n'
import ProvidersCommon from '../src/components/ProvidersCommon'
import { GoogleTagManager } from '@next/third-parties/google'
import AskForReview from '@/src/components/userActivity/AskForReview'
import { FbEventName, sendFbEventByName } from '@/src/analytics/fbPixelData'
import { VercelToolbar } from '@vercel/toolbar/next'
import { rebrandFontVariables } from '@/src/rebrand/fonts'
import { useUserIp } from '@/src/contexts/user-ip-context'

type NextLayoutComponentType<P = {}> = NextComponentType<NextPageContext, any, P> & {
  auth?: AuthType
  getLayout?: (page: React.ReactNode) => React.ReactNode
}

type WrappedAppProps = AppProps<{ auth?: AuthType; Component: NextLayoutComponentType }> &
  PageCtxType & {
    store: any
    setToken: any
    apis: any
  }

function MyApp({ Component, pageProps, store, setToken, apis }: WrappedAppProps) {
  const router = useRouter()
  const { t } = getTranslate()
  const isWebView = useIsAppWebView()
  const user = store.getState().user
  const { userIp } = useUserIp()
  useScrollPosition()

  // const shouldInjectToolbar = process.env.NODE_ENV === 'development'
  const shouldInjectToolbar = false

  useEffect(() => {
    const handlePageView = (url) => {
      pageview(url, { id: user.id })
      sendFbEventByName({
        eventName: FbEventName.PageView,
        user: { ...user, userIp },
        customData: {
          url,
        },
      })
    }
    router.events.on('routeChangeComplete', handlePageView)

    return () => {
      router.events.off('routeChangeComplete', handlePageView)
    }
  }, [router.events, user.id])

  useEffect(() => {
    Toaster.setTranslator(t)
    dayjs.locale(process.env.NEXT_PUBLIC_LOCALE)
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
      weekStart: 1,
    })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      sendFbEventByName({ eventName: 'timer_25sec', user: { ...user, userIp } })
    }, 25000)

    return () => clearTimeout(timer)
  }, [])

  const getLayout = (Component as NextLayoutComponentType).getLayout || ((page: React.ReactNode) => page)

  const renderPage = () => getLayout(<Component {...pageProps} apis={apis} setToken={setToken} t={t} />)

  return (
    <>
      <HeadCommonTags />

      {isWebView ? (
        <style>{`#CybotCookiebotDialog{display:none!important;}`}</style>
      ) : (
        <div className="cookiebot-overlay" />
      )}
      <div className={`app-container ${isWebView ? 'is-web-view' : ''} ${rebrandFontVariables}`}>
        <ProvidersCommonPageRouter {...{ store, setToken, apis }}>
          <ProvidersCommon>
            <FetchCommonIntoRedux>
              {(Component as NextLayoutComponentType).auth ? (
                <Auth auth={(Component as NextLayoutComponentType).auth}>
                  <ProvidersAuthenticated>
                    <AskForReview />

                    {renderPage()}
                  </ProvidersAuthenticated>
                </Auth>
              ) : (
                renderPage()
              )}
              {shouldInjectToolbar && <VercelToolbar />}
            </FetchCommonIntoRedux>
          </ProvidersCommon>
        </ProvidersCommonPageRouter>
      </div>

      <GoogleTagManager gtmId={GTM_ID} />
    </>
  )
}

export default withReduxAndAxios(createStore, createApis)(MyApp)
