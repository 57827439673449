import useSWR from 'swr'
import { useApis } from '../contexts/api-context'
import { AppointmentType } from '../types/TherapistStateType'
import { useSession } from '../contexts/session-context'

const fetchFunction = async (api: any): Promise<AppointmentType[]> => {
  const res = await api.getAllAppointments()
  return res.data
}

const useAllEvents = () => {
  const { api } = useApis()
  const { isLoggedIn } = useSession()
  const { data, isLoading, mutate } = useSWR(isLoggedIn ? 'useAllEvents' : null, () => fetchFunction(api))
  return { events: data, isLoading, refetchEvents: mutate }
}

export default useAllEvents
