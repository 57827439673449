import { NextRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import unauthorizedConfig from '../config/unauthorizedConfig.json'

const joinUrlWithQuery = (redirectUrl: string, queries: string[], router: NextRouter) => {
  let newRedirectUrl = redirectUrl
  queries.forEach((key, index) => {
    const value = router.query[key]
    if (typeof value !== 'undefined') {
      if (index === 0) {
        newRedirectUrl = `${newRedirectUrl}?${key}=${value}`
      } else {
        newRedirectUrl = `${newRedirectUrl}&${key}=${value}`
      }
    }
  })
  return newRedirectUrl
}

// this whole function is legacy
// we should just pass redirectUrlOverride to /login and react accordingly
export const redirectUnauthorized = ({
  router,
  redirectUrlOverride,
  loginPage = 'login',
}: {
  router: NextRouter
  redirectUrlOverride: string | null
  loginPage?: string
}) => {
  if (unauthorizedConfig.isEnabled) {
    // this is the correct way forward
    if (redirectUrlOverride) {
      router.replace(`/${loginPage}?redirectUrl=${encodeURI(redirectUrlOverride)}`)
      return
    }

    // the following is legacy code, that should be gradually removed
    // once the custom redirects from unauthorized config are working well
    let redirectUrl = unauthorizedConfig.defaultRedirect
    const foundPath =
      router &&
      unauthorizedConfig.routesWithSpecificValuesIncluded.find(
        (route: { path: string; valueToGetFromRouter: string }) => route.path === router.pathname,
      )
    const valueFromProps = foundPath ? router.query[foundPath.valueToGetFromRouter] : ''
    if (router && foundPath && valueFromProps) {
      redirectUrl = `${foundPath.pathToRedirect}${valueFromProps}`
    }
    if (
      typeof foundPath !== 'undefined' &&
      Array.isArray(foundPath.queries) &&
      foundPath.queries.length > 0 &&
      router &&
      router.query
    ) {
      redirectUrl = joinUrlWithQuery(redirectUrl, foundPath.queries, router)
    }

    router
      .push(
        redirectUrl === unauthorizedConfig.defaultRedirect
          ? redirectUrl
          : `${foundPath.pathToRedirect}[${foundPath.valueToGetFromRouter}]`,
        redirectUrl,
      )
      .catch((err) => {
        Sentry.captureException(err)
      })
  }
}
