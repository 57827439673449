import dayjs from 'dayjs'
import { VALIDATION_TYPE } from '../redux/ValidationRedux'

const validatePhone = (value, prefixRequired?: boolean) => {
  if (!value) return true

  if (prefixRequired) {
    return /^((00|\+)\d{3})+ ?[1-9]\d{2} ?\d{3} ?\d{3}$/.test(value.trim())
  } else {
    return /^((00|\+)\d{3})? ?[1-9]\d{2} ?\d{3} ?\d{3}$/.test(value.trim())
  }
}

const validateEmail = function validateEmail(value) {
  if (!value) return true
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\u0621-\u064A\-0-9]+\.)+[a-zA-Z\u0621-\u064A]{2,}))$/.test(
    value,
  )
}

const isNumeric = function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const createValidationError = (msg: string, data?: Object) => ({
  msg,
  data: { ...data, type: VALIDATION_TYPE.error },
})

const canBeEmpty = (value: any) => typeof value === 'undefined' || (value !== null && value.length === 0)

const regExp = (value: any, regExpTest: string) =>
  new RegExp(regExpTest).test(value) ? true : createValidationError('badRegExp', { pattern: regExpTest })

const minLength = (value: any, min: number) =>
  typeof value !== 'undefined' && min > 0 && value.length >= min
    ? true
    : createValidationError('tooShort', { length: min })

const maxLength = (value: any, max: number) =>
  typeof value !== 'undefined' && max > 0 && value.length <= max
    ? true
    : createValidationError('tooLong', { length: max })

const isPhone = (value: any) =>
  value !== null && typeof value !== 'undefined' && validatePhone(value, true)
    ? true
    : createValidationError('badPhoneFormat')

const isEmail = (value: any) =>
  typeof value !== 'undefined' && validateEmail(value) ? true : createValidationError('badEmailFormat')

const isPassword = (value: any) =>
  // new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]{6,}$').test(value)
  value && value.length > 5 ? true : createValidationError('badPasswordFormat')

const isNumber = (value: any) => (isNumeric(value) ? true : createValidationError('numeric'))

const inRange = (value: any, range: { start: number; end: number }) =>
  isNumber(value) &&
  range.start !== null &&
  range.end !== null &&
  !(range.start > parseInt(value, 10) || range.end < parseInt(value, 10))
    ? true
    : createValidationError('range', { start: range.start, end: range.end })

const isInteger = (value: any) => (/^\d+$/.test(value) ? true : createValidationError('integer'))

const isRequired = (value: any) =>
  typeof value !== 'undefined' && `${value}`.length > 0 ? true : createValidationError('required')

const isFullName = (value: any) =>
  /^[a-žA-Ž]+(?:\s[a-žA-Ž]+)+$/.test(value.trim()) ? true : createValidationError('fullName')

const isSame = (value: any, config: string, data: Object) =>
  typeof value !== 'undefined' && value === data[config] ? true : createValidationError('doesntSame')

const isGreaterThan = (value: any, config: string, data: Object) =>
  (typeof value === 'number' && typeof data[config] === 'number' && value >= data[config]) || typeof value !== 'number'
    ? true
    : createValidationError('dateRelation')

const isSmallerThan = (value: any, config: string, data: Object) =>
  (typeof value === 'number' && typeof data[config] === 'number' && value <= data[config]) || typeof value !== 'number'
    ? true
    : createValidationError('dateRelationTo')

const isChecked = (value: any, config: string) => (value === config ? true : createValidationError('notChecked'))

const timestampGTNow = (value: any) => (value || 0) < +new Date()

const isDateFormatValid = (value: any, format: string) => {
  if (dayjs.unix(value).isValid() || value === '') {
    return true
  }
  return dayjs(value, format, true).isValid() ? true : createValidationError('date')
}

const checkString = (value: any) =>
  /^[^*|":<>[\]{}\\();@&$0-9]+$/.test(value) ? true : createValidationError('illegalCharacters')

const isEveryRequired = (values: any[]) =>
  values.every((currentValue) => currentValue.value === true) ? true : createValidationError('allValuesTrue')

const isNotSame = (value: any, config: string, data: Object) =>
  typeof value !== 'undefined' && value !== data[config] ? true : createValidationError('isNotSame')

const isSafeRedirectUrl = (value: string): boolean => {
  // We allow relative and same-host absolute URLs.
  // In practice only relative URLs should be / are used, but let's stay on the safe side and allow absolute URLs also.
  // If the value is a relative URL, the base = location.origin gets applied and the check passes.
  // If the value is an absolute URL with our host, the check passes and everything is peachy.
  // If the value is an absolute URL with a different host, the base is not applied and the check fails.
  const url = new URL(value, window.location.origin)

  return url.host === window.location.host
}

export default {
  canBeEmpty,
  inRange,
  isChecked,
  isDateFormatValid,
  isEmail,
  isInteger,
  isNumber,
  isPassword,
  isPhone,
  isRequired,
  isSame,
  maxLength,
  minLength,
  regExp,
  timestampGTNow,
  checkString,
  isGreaterThan,
  isEveryRequired,
  isNotSame,
  isSmallerThan,
  isFullName,
  isSafeRedirectUrl,
}
