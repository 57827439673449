import { useApis } from '../../contexts/api-context'
import useSWR from 'swr/immutable'
import { ApiEndpoints } from '../../services/api'

export type QuotaType = {
  readonly id: number
  readonly quota: number
  readonly quotaPerCompany: number
  readonly endDate: string
  readonly isPerUser: boolean
  readonly remainingEvents: number
  readonly services: number[]
  readonly startDate: string
  readonly maxPrice?: number
}

const fetchCompanyQuotaData = async (api: ApiEndpoints, companyId): Promise<QuotaType[]> => {
  const result = await api.getCompanyQuota(companyId)
  return result.data
}

const useGetCompanyQuota = (companyId?: null | number) => {
  const { api } = useApis()

  const { data, mutate, isLoading } = useSWR(
    () => (companyId ? ['getCompanyQuota', companyId] : null),
    () => fetchCompanyQuotaData(api, companyId),
  )

  return { data, refetchQuota: mutate, isLoading }
}

export default useGetCompanyQuota
