// a library to wrap and simplify api calls
import axios from 'axios'
import { CompanyEmployeeToken } from '../types/UserStateType'
import { LOCALE } from '../constants/locale.constants'
import UserI = AppleSignInAPI.UserI
import AuthorizationI = AppleSignInAPI.AuthorizationI

export const createAxiosApi = () =>
  axios.create({
    baseURL: `/api/${process.env.NEXT_PUBLIC_API_VERSION}/`,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': LOCALE,
      Accept: '*/*',
    },
    timeout: 20000,
  })

export const axiosApi = createAxiosApi()

const endpoints = (api) => {
  const setHeader = (name: string, value: string) => {
    if (value) {
      api.defaults.headers.common[name] = value
    } else {
      delete api.defaults.headers.common[name]
    }
  }
  return {
    // POST
    login: (email: string, password: string, companyEmployee: CompanyEmployeeToken) =>
      api.post('login', { email, password, companyEmployee }),
    loginFacebook: (code: string, companyEmployee: CompanyEmployeeToken, doNotCreateNewUser?: boolean) =>
      api.post('facebook/oauth', { code, companyEmployee, doNotCreateNewUser }),
    loginGoogle: (idToken: string, companyEmployee: CompanyEmployeeToken, doNotCreateNewUser?: boolean) =>
      api.post('google/oauth', { idToken, companyEmployee, doNotCreateNewUser }),
    loginApple: ({
      authorization,
      user,
      companyEmployee,
      doNotCreateNewUser,
    }: {
      authorization: AuthorizationI
      user: UserI
      companyEmployee?: CompanyEmployeeToken
      doNotCreateNewUser?: boolean
    }) => api.post('apple/oauth', { authorization, user, companyEmployee, doNotCreateNewUser }),
    resetPassword: (email: string) => api.post('register/reset-password-request', { email }),
    refreshToken: (refreshToken: string) =>
      api.post('oauth/refresh-token', {}, { headers: { Authorization: `Bearer ${refreshToken}` } }),
    register: (data: Object) => api.post('register', data),
    registerFromSocial: (data: Object) => api.post('register/from-social', data),
    addAddress: (address: string) => api.post('sendpulse/add-address', { address }),

    // helpers
    setHeader,
    setAuthorization: (auth: string) => {
      setHeader('Authorization', auth)
    },
  }
}

export const rawEndpoints = endpoints

export default endpoints(axiosApi)
