import { createActions, createReducer } from 'reduxsauce'
import thunk from 'redux-thunk'
import { assocPath, mergeDeepRight } from 'ramda'
import FetchingMiddleware from '../services/FetchingMiddleware'
import type { TherapistStateType } from '../types/TherapistStateType'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  onChangeOpenTherapistsValue: ['path', 'value'],
  onMergeOpenTherapistsValue: ['value'],
})

// ======================================================
// Middleware Configuration
// ======================================================
const middleware = [thunk]

middleware.push(FetchingMiddleware as any)

export const OpenTherapistsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  therapists: [],
}

/* ------------- Reducers ------------- */

export const onChangeOpenTherapistsValueR = (
  state: TherapistStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): TherapistStateType => assocPath(path.split('.'), value, state)

export const onMergeOpenTherapistsValueR = (
  state: TherapistStateType,
  { value }: { value: Object | boolean | number | string },
): TherapistStateType => mergeDeepRight(state, value)

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_OPEN_THERAPISTS_VALUE]: onChangeOpenTherapistsValueR,
  [Types.ON_MERGE_OPEN_THERAPISTS_VALUE]: onMergeOpenTherapistsValueR,
} as any)
