import { from } from 'rxjs'
import Toaster from '../utilities/toaster'
import GeneralActions from '../redux/GeneralRedux'
import type { DiagnosisStateType } from '../types/DiagnosisStateType'
import { handleError } from '../utilities/handlerError'

export const generalErrorHandler =
  (path: string = 'general', additionalActions?: any[]) =>
  (error: any) => {
    handleError(path, error)
    return from([GeneralActions.onStopFetching([path]), ...(additionalActions ? additionalActions : [])])
  }

export const generalSuccessHandler = (successMessage: string = '', path: string = 'general') => {
  if (successMessage) {
    Toaster.success(successMessage)
  }
  return GeneralActions.onStopFetching([path])
}

export const diagnosisSerializerOut = (diagnosis: DiagnosisStateType) =>
  Object.keys(diagnosis).reduce<Object>((acc, key) => {
    const keyValues = diagnosis[key]
    if (!Array.isArray(keyValues) && typeof keyValues === 'object') {
      acc[key] = Object.values(keyValues).reduce<number>((accumulator, currentValue) => {
        if (typeof currentValue === 'number') {
          accumulator += currentValue
        }
        return accumulator
      }, 0)
    } else {
      acc[key] = diagnosis[key]
    }
    return acc
  }, {})

export const getDiagnosisShortcutData = (diagnosis: DiagnosisStateType) => {
  const data = {
    ...diagnosis,
    lead: diagnosis.shortDiagnosisV2.lead * 5,
    emotions: diagnosis.shortDiagnosisV2.emotions * 3,
    past: diagnosis.shortDiagnosisV2.past * 4,
    personality: diagnosis.shortDiagnosisV2.personality * 5,
  }
  delete data.shortDiagnosisV2
  return data
}

export const getDiagnosisShortcutDataV2 = (diagnosis: DiagnosisStateType) => {
  const data = {
    ...diagnosis,
    lead: diagnosis.shortDiagnosisV2.lead * 5,
    emotions: diagnosis.shortDiagnosisV2.emotions * 3,
    past: diagnosis.shortDiagnosisV2.past * 4,
    personality: diagnosis.shortDiagnosisV2.personality * 5,
    services: [diagnosis.service],
    specialization: diagnosis.specialization,
  }
  delete data.shortDiagnosisV2
  delete data.service
  delete data.diagnosisFinished
  return data
}
