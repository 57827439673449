export const convertArrayToObject = (array: Object[], key: string) =>
  array.reduce((obj, item) => {
    if (!Array.isArray(obj[item[key]])) {
      obj[item[key]] = []
    }
    obj[item[key]].push({ ...item })
    return obj
  }, {})

export const convertArrayToObjectNoDuplicateKey = (array: Object[], key: string, dataKey: string) =>
  array.reduce((acc, item) => {
    acc[item[key]] = item[dataKey]
    return acc
  }, {})
