import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from } from 'rxjs'
import Router from 'next/router'
import DiagnosisActions, { DiagnosisTypes, INITIAL_STATE } from '../redux/DiagnosisRedux'
import ClientActions, { SHOW_THERAPISTS } from '../redux/ClientRedux'
import GeneralActions from '../redux/GeneralRedux'
import {
  diagnosisSerializerOut,
  generalErrorHandler,
  generalSuccessHandler,
  getDiagnosisShortcutData,
} from './EpicHelpers'
import type { ReduxState } from '../store/reducers'
import { ApiEndpoints } from '../services/api'
import { hasDiagnosisFilledOut } from '@/src/components/diagnosis/guide/utils'

export default class DiagnosisEpic {
  static getDiagnosis = (api: ApiEndpoints) => (action$: any, state$: { value: ReduxState }) => {
    return action$.pipe(
      ofType(DiagnosisTypes.GET_DIAGNOSIS_REQUEST),
      switchMap(({ diagnosis, isTherapist }) =>
        from(api.getDiagnosis()).pipe(
          mergeMap((response: any) => {
            const data = response.data
            const actions = [generalSuccessHandler('')]
            const { diagnosisFinished } = diagnosis || state$.value.diagnosis
            if (diagnosisFinished) {
              actions.push(
                DiagnosisActions.sendDiagnosisTestRequest(diagnosis, isTherapist),
                GeneralActions.onStopFetching(['diagnosis']),
                GeneralActions.onChangeGeneralValue('lastScrollPosition./client/reservation', 0),
              )
            } else {
              actions.push(
                ClientActions.onMergeClientValue({
                  diagnosisTest: data.diagnosis,
                  favouredTherapists: data.therapists,
                }),
                GeneralActions.onStopFetching(['diagnosis']),
              )
            }
            return actions
          }),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  }

  static sendDiagnosisTest = (api: ApiEndpoints) => (action$: any, state$: { value: ReduxState }) => {
    return action$.pipe(
      ofType(DiagnosisTypes.SEND_DIAGNOSIS_TEST_REQUEST),
      switchMap(({ diagnosis, isTherapist }) => {
        const diagnosisData = diagnosis || state$.value.diagnosis
        const dataToSend = isTherapist ? diagnosisSerializerOut(diagnosisData) : getDiagnosisShortcutData(diagnosisData)
        return from(api.saveDiagnosis(dataToSend)).pipe(
          mergeMap((response: any) => {
            const data = response.data
            const actions = [generalSuccessHandler('')]
            actions.push(
              ClientActions.onMergeClientValue({
                diagnosisTest: data.diagnosis,
                favouredTherapists: data.therapists,
                showTherapists: SHOW_THERAPISTS.ALL,
              }),
              DiagnosisActions.onMergeDiagnosisValue(INITIAL_STATE),
            )

            if (isTherapist) {
              Router.push(`/therapist/diagnosis/finish`)
            } else if (hasDiagnosisFilledOut(diagnosisData)) {
              // works after registration
              Router.push(`/client/recommended-therapist`)
            } else {
              Router.push(`/client/reservation?service=${data.diagnosis.services[0]}`)
            }
            return from(actions)
          }),
          catchError(generalErrorHandler()),
        )
      }),
    )
  }

  static getAllActions = ({ api }: { api: ApiEndpoints }) => [
    DiagnosisEpic.sendDiagnosisTest(api),
    DiagnosisEpic.getDiagnosis(api),
  ]
}
