import { combineReducers } from 'redux'
import type { GeneralStateType } from '../types/GeneralStateType'
import type { ValidationStateType } from '../types/ValidationStateType'
import type { UserStateType } from '../types/UserStateType'
import type { ClientStateType } from '../types/ClientStateType'
import type { TherapistStateType, TherapistType } from '../types/TherapistStateType'
import type { DiagnosisStateType } from '../types/DiagnosisStateType'

import * as GeneralRedux from '../redux/GeneralRedux'
import * as ValidationRedux from '../redux/ValidationRedux'
import * as UserRedux from '../redux/UserRedux'
import * as ClientRedux from '../redux/ClientRedux'
import * as TherapistRedux from '../redux/TherapistRedux'
import * as DiagnosisRedux from '../redux/DiagnosisRedux'
import * as OpenTherapistsRedux from '../redux/OpenTherapistsRedux'

export type ReduxState = {
  general: GeneralStateType
  validation: ValidationStateType
  user: UserStateType
  client: ClientStateType
  therapist: TherapistStateType
  diagnosis: DiagnosisStateType
  openTherapists: { therapists: TherapistType[]; freeTherapists?: TherapistType[] }
}

export const reduxStore = {
  general: {
    redux: GeneralRedux,
    whitelist: ['timeFilter', 'impersonateActive', 'services', 'lastScrollPosition'],
    version: 2,
  },
  validation: {
    redux: ValidationRedux,
    version: 1,
  },
  user: {
    redux: UserRedux,
    version: 2,
    whitelist: [
      'email',
      'id',
      'firstName',
      'lastName',
      'password',
      'phone',
      'note',
      'verified',
      'type',
      'videoChatCredentials',
      'sendMessageNotifications',
      'chat',
      'visitedTherapistIds',
    ],
  },
  client: {
    redux: ClientRedux,
    version: 1,
    whitelist: [
      'schedules',
      'appointments',
      'feedback',
      'therapistSpecializations',
      'recentlyVisitedTherapist',
      'diagnosisTest',
      'favouredTherapists',
      'clientCalendarDefaultDate',
      'showTherapists',
    ],
  },
  therapist: {
    redux: TherapistRedux,
    version: 1,
    whitelist: [
      'schedule',
      'appointments',
      'clients',
      'certificates',
      'description',
      'education',
      'experience',
      'firstName',
      'lastName',
      'majorSpecializations',
      'minorSpecializations',
      'image',
      'recommendations',
      'verified',
      'videoId',
      'therapistId',
      'therapistUserId',
      'therapistSpecializations',
      'visible',
      'services',
      'prebookingMode',
    ],
  },
  diagnosis: {
    redux: DiagnosisRedux,
    version: 2,
    whitelist: ['lead', 'emotions', 'past', 'personality', 'goal', 'specialization', 'sex', 'diagnosisFinished'],
  },
  openTherapists: {
    redux: OpenTherapistsRedux,
    version: 2,
    whitelist: ['therapists', 'freeTherapists'],
  },
}

export const toCombineReducers = {
  general: reduxStore.general.redux.reducer,
  validation: reduxStore.validation.redux.reducer,
  user: reduxStore.user.redux.reducer,
  client: reduxStore.client.redux.reducer,
  therapist: reduxStore.therapist.redux.reducer,
  diagnosis: reduxStore.diagnosis.redux.reducer,
  openTherapists: reduxStore.openTherapists.redux.reducer,
}

const makeRootReducer = () => combineReducers(toCombineReducers)

export default makeRootReducer
