import React from 'react'
import { Provider } from 'react-redux'
import { ApisProvider } from '../contexts/api-context'
import { SessionProvider } from '../contexts/session-context'
import GrowthBookCustomProvider from './GrowthBook'
import { Store } from 'redux'
import { ApisType } from '../store/createApis'
import { UserIpProvider } from '../contexts/user-ip-context'

type Props = {
  store: Store
  setToken: (name: string, token: string) => void
  apis: ApisType
  children: React.ReactNode
}

const ProvidersCommonPageRouter: React.FC<Props> = ({ store, setToken, apis, children }) => {
  return (
    <UserIpProvider>
      <Provider store={store}>
        <ApisProvider apis={apis} setToken={setToken}>
          <SessionProvider>
            <GrowthBookCustomProvider>{children}</GrowthBookCustomProvider>
          </SessionProvider>
        </ApisProvider>
      </Provider>
    </UserIpProvider>
  )
}

export default ProvidersCommonPageRouter
