import validDynamicRoutesConfig from '@/src/config/validDynamicRoutesConfig.json'

export const localeConfig = {
  en: {
    domain: 'com',
    displayName: 'English',
  },
  cs: {
    domain: 'cz',
    displayName: 'Česky',
  },
  ro: {
    domain: 'ro',
    displayName: 'Română',
  },
  pl: {
    domain: 'pl',
    displayName: 'Polski',
  },
  sk: {
    domain: 'sk',
    displayName: 'Slovenčina',
  },
  el: {
    domain: 'gr',
    displayName: 'Ελληνικά',
  },
  hu: {
    domain: 'hu',
    displayName: 'Magyar',
  },
  uk: {
    domain: 'com.ua',
    displayName: 'Українська',
  },
  sl: {
    domain: 'si',
    displayName: 'Slovenščina',
  },
  lt: {
    domain: 'lt',
    displayName: 'Lietuvių',
  },
} as const

export type Locale = keyof typeof localeConfig
export const locales = Object.keys(localeConfig) as Locale[]

export const LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const PUBLIC_THERAPISTS_PAGE_SLUG = validDynamicRoutesConfig.locales[LOCALE]
export const PUBLIC_THERAPISTS_PAGE_URL = `${process.env.NEXT_PUBLIC_WORDPRESS_WEB}${PUBLIC_THERAPISTS_PAGE_SLUG}`

export const getPublicTherapistSlugForLocale = (locale: Locale) => validDynamicRoutesConfig.locales[locale]

export const isRoutePublicTherapistList = (route: string) =>
  route.includes(PUBLIC_THERAPISTS_PAGE_SLUG) || route.includes(encodeURIComponent(PUBLIC_THERAPISTS_PAGE_SLUG))
