'use client'

import React, { useMemo, useContext, createContext, useState, useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

type UserIpContextType = { userIp?: string }

const UserIpContext = createContext<UserIpContextType>({})

const UserIpProvider: React.FC<{ children: React.ReactNode }> = ({ ...props }) => {
  const [userIp, setUserIp] = useState<string>('')

  useEffect(() => {
    const getIp = async () => {
      try {
        const res = await fetch('/api/v2/initialData', {
          method: 'POST',
        })
        const data = await res.json()
        if (data.ip) setUserIp(data.ip)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    getIp()
  }, [])

  const value = useMemo(() => ({ userIp }), [userIp])

  return <UserIpContext.Provider value={value} {...props} />
}

const useUserIp = () => useContext(UserIpContext)

export { UserIpProvider, useUserIp }
