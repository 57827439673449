import { combineEpics } from 'redux-observable'

import ClientEpic from './ClientEpic'
import UserEpic from './UserEpic'
import TherapistEpic from './TherapistEpic'
import GeneralEpic from './GeneralEpic'
import DiagnosisEpic from './DiagnosisEpic'

// epics
export const epics = (apis) => [
  ...ClientEpic.getAllActions(apis),
  ...UserEpic.getAllActions(apis),
  ...TherapistEpic.getAllActions(apis),
  ...GeneralEpic.getAllActions(apis),
  ...DiagnosisEpic.getAllActions(apis),
]

const rootEpic = (apis: Object) => combineEpics(...epics(apis))

export default rootEpic
