import React from 'react'
import Image from 'next/image'
import Icon from '../icons/Icon'
import { getTranslate } from '@/src/utilities/i18n'
import api from '@/src/services/api'
import dayjs from 'dayjs'
import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'
import { APP_USER_ACTIVITY_TYPE } from './constants'
import useUserActivity from './useUserActivity'
import useAllEvents from '@/src/hooks/useAllEvents'
import { groupAllEvents } from '../homePage/utils'
import useUser from '@/src/hooks/useUser'
import Loader from '@/src/components/Loader'
import { RadixButton } from '@/src/rebrand/buttonRadix'

const REVIEW_LINK = 'https://g.page/r/Cebjvlc6_sF3EBM/review'
const ASK_LATER_LIMIT_DAYS = 30

const AskForReviewContent: React.FC = () => {
  const { t } = getTranslate()
  const { isClient } = useUser()
  const { events: allEvents, isLoading: isEventsLoading } = useAllEvents()
  const { futureEvents, pastEvents } = groupAllEvents(allEvents)

  const { data: activities, refetchActivities, isLoading: isActivitiesLoading } = useUserActivity()

  const isCorrectNumberOfEvents = pastEvents?.length >= 2 || (pastEvents?.length >= 1 && futureEvents?.length >= 1)

  const isPermanentlyHidden = Boolean(
    activities?.filter((activity) =>
      [
        APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_REPORT_PROBLEM,
        APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_FIVE_STARS,
      ].includes(activity.type),
    ).length,
  )

  const isTemporarilyHidden = Boolean(
    activities?.filter(
      (activity) =>
        activity.type === APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_LATER &&
        dayjs().isBefore(dayjs(activity.updatedAt).add(ASK_LATER_LIMIT_DAYS, 'days')),
    ).length,
  )

  const isFlagOn = useFeatureIsOn('ask-for-review')

  const isSafariAgent = navigator?.userAgent.indexOf('Safari') !== -1
  const isChromeAgent = navigator?.userAgent.indexOf('Chrome') !== -1
  const isSafari = isChromeAgent ? false : isSafariAgent

  const isVisible =
    !isActivitiesLoading &&
    !isEventsLoading &&
    isFlagOn &&
    isClient &&
    isCorrectNumberOfEvents &&
    !isTemporarilyHidden &&
    !isPermanentlyHidden &&
    !isSafari

  const hideTemporarily = async () => {
    await api.setUserActivity(APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_LATER)
    refetchActivities()
  }

  const createReview = async () => {
    await api.setUserActivity(APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_FIVE_STARS)
    refetchActivities()
    window.open(REVIEW_LINK, '_blank', 'noopener noreferrer')
  }

  const reportProblem = async () => {
    await api.setUserActivity(APP_USER_ACTIVITY_TYPE.ASK_FOR_REVIEW_CLICK_REPORT_PROBLEM)
    refetchActivities()
    window.open(t('component.askForReview.link'), '_blank', 'noopener noreferrer')
  }

  if (!isVisible) return null

  return (
    <div className="fixed bottom-2 right-2 z-20 max-w-[300px] rounded-2xl bg-petroleum text-center text-white">
      <div className="flex w-full justify-end">
        <RadixButton className="p-1 focus:shadow-none" variant="inline" onClick={hideTemporarily}>
          <Icon icon="cross" className="text-right" color="primary" size="md" />
        </RadixButton>
      </div>
      <div className="px-6 pb-6">
        <div className="w-full">
          <Image priority alt="Roman" width="60" height="60" sizes="60px" className="" src="/images/roman.png" />
        </div>
        <div className="mb-4 text-sm font-bold">Roman</div>
        <div className="m-2 rounded-xl bg-petroleum-background px-2 py-1 text-sm font-bold text-petroleum">
          {t('component.askForReview.thankYou')}
        </div>
        <div className="mb-2 text-xl font-bold">{t('component.askForReview.title')}</div>
        <div className="mb-4 text-sm big-phone:text-base">{t('component.askForReview.description')}</div>

        <div className="mb-3">
          <RadixButton onClick={createReview} size="small" className="w-full">
            {t('component.askForReview.rate')}
          </RadixButton>
        </div>
        <div className="mb-3">
          <RadixButton onClick={reportProblem} size="small" className="w-full">
            {t('component.askForReview.shareProblem')}
          </RadixButton>
        </div>
        <div>
          <RadixButton onClick={hideTemporarily} size="small" className="w-full">
            {t('component.askForReview.notNow')}
          </RadixButton>
        </div>
      </div>
    </div>
  )
}

const AskForReview = (props) => {
  return (
    <FeaturesReady timeout={5 * 1000} fallback={<Loader visible loaderType="section-large" />}>
      <AskForReviewContent {...props} />
    </FeaturesReady>
  )
}

export default AskForReview
