import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { from } from 'rxjs'
import ClientActions, { ClientTypes } from '../redux/ClientRedux'
import TherapistActions from '../redux/TherapistRedux'
import UserActions, { USER_TYPE } from '../redux/UserRedux'
import { generalErrorHandler, generalSuccessHandler } from './EpicHelpers'
import { ApiEndpoints } from '../services/api'
import { TherapistType } from '../types/TherapistStateType'
import { ReduxState } from '../store/reducers'

export default class ClientEpic {
  static getTherapists = (api: ApiEndpoints) => (action$: any, state$: { value: ReduxState }) =>
    action$.pipe(
      ofType(ClientTypes.GET_THERAPISTS_REQUEST),
      mergeMap(() => {
        return from(api.getOpenTherapists()).pipe(
          mergeMap(({ data }: { data: TherapistType[] }) => {
            const user = state$.value.user
            const subscriptionTherapistIds =
              user.userSubscriptions?.map((subscription) => subscription.therapistId) || []
            const therapists = data.filter(
              (therapist) =>
                therapist.visible ||
                user.visitedTherapistIds.includes(therapist.therapistId) ||
                subscriptionTherapistIds.includes(therapist.therapistId),
            )
            return from([
              ClientActions.onChangeClientValue('therapists', therapists),
              ClientActions.onChangeClientValue('freeTherapists', therapists),
              generalSuccessHandler('', 'therapists'),
            ])
          }),
          catchError(generalErrorHandler('therapists')),
        )
      }),
    )
  static getReviews = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(ClientTypes.GET_THERAPIST_REVIEWS_REQUEST),
      switchMap(({ therapistId, therapistUserId, userType }) =>
        from(api.getReviews(therapistUserId)).pipe(
          mergeMap((response: any) => {
            const actions = [generalSuccessHandler('')]
            if (USER_TYPE.CLIENT === userType) {
              actions.push(ClientActions.onChangeClientValue(`reviews.${therapistId}`, response.data))
            } else {
              actions.push(TherapistActions.onChangeTherapistValue('reviews', response.data))
            }
            return from(actions)
          }),
          catchError(generalErrorHandler()),
        ),
      ),
    )
  static cancelPrebookedAppointments = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(ClientTypes.CANCEL_PREBOOKED_APPOINTMENTS_REQUEST),
      switchMap(({ eventsId }) =>
        from(api.cancelPrebookedAppointments(eventsId)).pipe(
          mergeMap(() => from([UserActions.getAllAppointmentsRequest(USER_TYPE.CLIENT)])),
          catchError(generalErrorHandler()),
        ),
      ),
    )

  static getAllActions = ({ api }: { api: ApiEndpoints }) => [
    ClientEpic.getTherapists(api),
    ClientEpic.getReviews(api),
    ClientEpic.cancelPrebookedAppointments(api),
  ]
}
