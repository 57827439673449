import { createActions, createReducer } from 'reduxsauce'
import { mergeDeepRight, assocPath } from 'ramda'
import thunk from 'redux-thunk'
import FetchingMiddleware from '../services/FetchingMiddleware'
import type { UserStateType } from '../types/UserStateType'

/* ------------- Types and Action Creators ------------- */

// ENUMS
export const USER_TYPE = {
  CLIENT: 0,
  THERAPIST: 1,
}

// These const are used to store that selected media devices in the localStorage

const { Types, Creators: UserActions } = createActions({
  onChangeUserValue: ['path', 'value'],
  onMergeUserValue: ['value'],
  registerRequest: ['data', 'therapistId', 'diagnosis', 'redirectUrl'],
  loginRequest: ['email', 'password', 'therapistId', 'diagnosis', 'redirectUrl', 'companyEmployee'],
  loginRequestFacebook: ['code', 'therapistId', 'diagnosis', 'redirectUrl', 'doNotCreateNewUser', 'companyEmployee'],
  loginRequestGoogle: ['idToken', 'therapistId', 'diagnosis', 'redirectUrl', 'doNotCreateNewUser', 'companyEmployee'],
  updateUserDataRequest: ['data'],
  getUserDataRequest: ['therapistId', 'withoutRedirect', 'gtm', 'diagnosisData', 'redirectUrl'],
  getAllAppointmentsRequest: ['userType', 'userId'],
  changePasswordRequest: ['token', 'email', 'password'],
  getTwilioFileRequest: ['url', 'filename', 'index'],
  getTherapistSpecializationsRequest: ['userType'],
})

// ======================================================
// Middleware Configuration
// ======================================================
const middleware = [thunk]

middleware.push(FetchingMiddleware as any)

export const UserTypes = Types
export default UserActions

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  email: '',
  id: null,
  firstName: '',
  lastName: '',
  phone: '',
  sendMessageNotifications: false,
  gdpr: false,
  type: null,
  videoChatCredentials: {},
  note: '',
  fullName: '',
  street: '',
  houseNumber: '',
  registrationNumber: '',
  city: '',
  zip: '',
  chat: {
    downloadFileIndex: {},
  },
  cancelModalVisible: false,
  visitedTherapistIds: [],
}

/* ------------- Reducers ------------- */

export const onChangeUserValueR = (
  state: UserStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): UserStateType => {
  return assocPath(path.split('.'), value, state)
}

export const onMergeUserValueR = (
  state: UserStateType,
  { value }: { value: Object | boolean | number | string },
): UserStateType => mergeDeepRight(state, value)

const removePasswordState = (state) => {
  delete state.password
  return state
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_USER_VALUE]: onChangeUserValueR,
  [Types.ON_MERGE_USER_VALUE]: onMergeUserValueR,
  [Types.REGISTER_REQUEST]: (state) => removePasswordState(state),
  [Types.LOGIN_REQUEST]: (state) => removePasswordState(state),
  [Types.LOGIN_REQUEST_FACEBOOK]: (state) => state,
  [Types.LOGIN_REQUEST_GOOGLE]: (state) => state,
  [Types.UPDATE_USER_DATA_REQUEST]: (state) => state,
  [Types.GET_USER_DATA_REQUEST]: (state) => state,
  [Types.GET_ALL_APPOINTMENTS_REQUEST]: (state) => state,
  [Types.CHANGE_PASSWORD_REQUEST]: (state) => removePasswordState(state),
  [Types.GET_TWILIO_FILE_REQUEST]: (state) => state,
  // this can get removed once old diagnosis is removed
  [Types.GET_THERAPIST_SPECIALIZATIONS_REQUEST]: (state) => state,
} as any)
