import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import GeneralActions from '../redux/GeneralRedux'
import UserActions, { USER_TYPE } from '../redux/UserRedux'

// this component provides an entrypoint to download the common requests
// so they won't need to be called at all pages that need the data
// ideally they would all be moved into swr hooks or server components and this component would be removed
const FetchCommonIntoRedux: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GeneralActions.getAllServicesRequest())
    dispatch(UserActions.getTherapistSpecializationsRequest(USER_TYPE.CLIENT))
  }, [])

  return <>{children}</>
}

export default FetchCommonIntoRedux
