import useGetCompanyQuota from './api/useGetCompanyQuota'
import useUser from './useUser'
import dayjs from 'dayjs'

const useCompanyBenefits = () => {
  const { user } = useUser()
  const company = user.companiesEmployee?.length > 0 && user.companiesEmployee[0]
  const benefitIsValid = dayjs(company?.CompanyEmployee?.validUntil).isAfter(dayjs())
  const { data: quotas, refetchQuota, isLoading } = useGetCompanyQuota(company?.id)
  const metaWorkplaceMemberId = user?.companiesEmployee?.[0]?.CompanyEmployee?.metaWorkplaceMemberId

  return {
    hasBenefitProgram: Boolean(company),
    benefitIsValid,
    quotas,
    refetchQuota,
    isLoading,
    company,
    metaWorkplaceMemberId,
  }
}

export default useCompanyBenefits
