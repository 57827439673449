// a library to wrap and simplify api calls
import axios, { AxiosInstance } from 'axios'
import { jwtDecode } from 'jwt-decode'
import type { AppointmentType, ScheduleType } from '../types/TherapistStateType'
import { REFRESH_TOKEN_KEY } from '../constants/storage'
import { FeedbackReviewData } from '../types/ClientStateType'
import { CompanyContact } from '../components/vibe/types'
import { CounsellingSessionType } from '../components/counselling/types'
import { SelectedTherapistType, SurveyAnswerType, SurveyFinishedType } from '../components/survey/types'
import { API_URL } from '../utilities/apiUtils'
import { HIDE_UI_PARAM_NAME } from '../hooks/useIsAppWebView'
import { VibeSettingsType } from '../components/vibe/types'
import { AUTOMATIC_MESSAGE_TYPES } from '@/src/components/therapistAccount/types'
import handleLogout from '../utilities/handleLogout'

const notServer = typeof window !== 'undefined'

const modifyOrigin = (origin?: string) => {
  if (!window) return origin

  const urlParams = new URLSearchParams(window.location.search)
  const isApp = urlParams.get(HIDE_UI_PARAM_NAME)

  if (isApp) {
    return `app-${origin || 'regular'}`
  } else {
    return origin
  }
}

export const createAxiosApi = () =>
  axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'cs', // TODO: Backend locale i18n
      Accept: 'application/json',
    },
    timeout: 20_000,
  })

export const axiosApi = createAxiosApi()

const endpoints = (api: AxiosInstance & { getState?: any; dispatch?: any }) => {
  const connect = (getState: any, dispatch: any) => {
    api.getState = getState
    api.dispatch = dispatch
  }

  const setHeader = (name: string, value: string) => {
    if (value) {
      api.defaults.headers.common[name] = value
    } else {
      delete api.defaults.headers.common[name]
    }
  }

  const handleLogoutWithHeaderReset = () => {
    setHeader('Authorization', '')
    handleLogout()
  }

  const refresh =
    (refreshApi: (token: string) => Promise<any>) =>
    (refreshToken, actions: Object[] = []) => {
      if (refreshToken) {
        return refreshApi(refreshToken)
          .then(({ data, status }) => {
            if (status === 200) {
              setHeader('Authorization', `Bearer ${data.accessToken}`)
              actions.forEach((action: Object) => {
                api.dispatch(action)
              })
            } else {
              handleLogoutWithHeaderReset()
            }
          })
          .catch(() => {
            handleLogoutWithHeaderReset()
          })
      }
    }

  const apiEndpoints = {
    // get
    getUserData: () => api.get('app-user'),
    getAllClients: () => api.get('therapist/clients'),
    getAllClientsSegmented: () => api.get('therapist/clients-segmented'),
    getAllServices: () => api.get('service'),
    getAllAppointments: () => api.get('event'),
    getCurrentAppointments: () => api.get('event/current'),
    getSingleAppointment: (eventId: number) => api.get(`event/${eventId}`),
    getPastAppointments: () => api.get('event/past'),
    getClientAppointments: (clientId) => api.get(`event/client/${clientId}`),
    getTherapistsByTime: (days: number[], times: string[], serviceId: number) =>
      api.post('therapist/available-at-times', { days, times, serviceId }),
    getOpenTherapists: () => api.get('therapist/list-open'),
    getOpenTherapistsByTime: (days: number[], times: string[], serviceId: number) =>
      api.post('therapist/available-at-times-open', { days, times, serviceId }),
    getSchedule: (therapistId?: number, dateTo?: number, serviceId?: number) =>
      therapistId && dateTo && serviceId
        ? api.get(`schedule/free/${therapistId}/${dateTo}/${serviceId}`)
        : api.get('schedule'),
    getVideoToken: (appointmentId: number) => api.get(`event/token/${appointmentId}`),
    getReviews: (therapistUserId: number) => api.get(`review/${therapistUserId}`),
    getAllMyReviews: () => api.get(`review/all`),
    getSpecializations: () => api.get('therapist/specializations'),
    getInsuranceCompanies: () => api.get('insurance'),
    getTrainings: () => api.get('therapist/trainings'),
    getTherapistData: (therapistUserId: number) => api.get(`therapist/detail/${therapistUserId}`),
    getDiagnosis: () => api.get('diagnosis'),
    getDiagnosisPreview: (data: {}) => api.post('diagnosis/preview', data),
    getSubscriptions: (initPrice?: number) => api.get(`subscriptions${initPrice ? `/${initPrice}` : ''}`),
    getMessagesToken: () => api.get('messages/token'),
    getInstantTherapists: () => api.get('instant/list'),
    getFeedback: (therapistId) => api.get(`review/client/${therapistId}`),
    getReferral: () => api.get('voucher/referral'),
    getStatistics: (lastDays: number) => api.get(`stats?lastDays=${lastDays}`),
    getFeedbackComparison: (lastDays: number) => api.get(`stats/nps?lastDays=${lastDays}`),
    getLongTimeTrend: (property: string) => api.get(`stats/long-term/${property}`),
    getDepartmentsLocations: (companyId: number) => api.get(`vibe/departments-locations/${companyId}`),
    getMoodChart: (
      companyId: number,
      departmentId: string[],
      locationId: string[],
      from: string,
      to: string,
      divisionId: string[],
    ) => {
      const urlParams = new URLSearchParams(
        Object.entries({ departmentId, locationId, divisionId }).flatMap(([key, values]) =>
          Array.isArray(values) ? values.map((value) => [key, value]) : [[key, values]],
        ),
      )
      return api.get(`vibe/mood/${companyId}?${urlParams.toString()}&from=${from}&to=${to}`)
    },
    getCounsellingSessions: () => api.get(`counselling`),
    getContactList: (companyId: number) => api.get(`company/${companyId}/contact`),
    getOneContact: (companyId: number, contactId: number) => api.get(`company/${companyId}/contact/${contactId}`),
    getQuestions: () => api.get('survey/question'),
    initSurvey: (userId: number, token: string) => api.get(`/survey/${userId}/${token}`),
    getCompany: (slug: string) => api.get(`company/public/${slug}`),
    getAllowedPremiumContent: () => api.get(`premium-content`),
    getVisiblePremiumContent: () => api.get(`premium-content/teasers`),
    getCompanyQuota: (companyId: number) => api.get(`company/${companyId}/quota`),
    getCompanySlackLink: (slug: string, uuid: string) => api.get(`slack/auth?slug=${slug}&onlyLink=true&uuid=${uuid}`),
    getCompanySlackConnection: (slug: string, companyId: number, code: string) =>
      api.get(`slack/auth/callback/${slug}/${companyId}?code=${code}`),
    getInvoiceForEvent: (eventId: number) => api.get(`/event/invoice/${eventId}`, { responseType: 'blob' }),
    getCompanyStats: (companyId: number, from: string, to?: string) =>
      api.get(`company/${companyId}/stats?from=${from}&to=${to}`),
    getMembershipPlans: () => api.get(`/membership/plans`),
    getVibeSettings: (companyId: number) => api.get(`/vibe/settings/${companyId}`),
    getIsInNewsletter: () => api.get(`sendpulse`),
    getAutomaticMessage: () => api.get(`/therapist/automatic-message`),
    getUserActivity: () => api.get(`/app-user/activity`),
    getUserReviews: (page: number) => api.get(`/review?page=${page}`),
    getVocativeName: (name: string) => api.get(`natural-language/name-vocative?nominative=${name}`),
    downloadClientInvoices: (url: string) =>
      api.get(`client-invoices/download/${url}`, {
        responseType: 'blob',
        timeout: 100_000,
      }),
    getClientInfo: (clientId: number) => api.get(`/app-user/client/${clientId}`),
    getSmartBillData: () => api.get('/therapist/smartbill'),
    getCallQualityReview: (eventId: number) => api.get(`/event/${eventId}/call-quality-review`),

    // post
    refreshToken: (refreshToken: string) =>
      api.post(
        'oauth/refresh-token',
        {},
        {
          headers: { Authorization: `Bearer ${refreshToken}` },
        },
      ),
    transaction: (appointmentId: number[], paymentMethod: string) =>
      api.post('transaction', {
        eventId: appointmentId,
        method: paymentMethod,
      }),
    deleteAppointment: (appointmentId: number) => api.post('deleteAppointment', { appointmentId }),
    addSchedule: (scheduleData: ScheduleType) => api.post('schedule', { ...scheduleData }),
    reserveAppointment: ({
      events,
      emailNote = '',
      videoChat = false,
      timezone,
      initialVoucherCall,
      origin,
      originParam,
    }: {
      events: AppointmentType[]
      emailNote?: string
      videoChat?: boolean
      timezone?: string
      initialVoucherCall?: boolean
      origin?: string
      originParam?: number
    }) =>
      api.post('event', {
        events,
        emailNote,
        onMeeting: videoChat,
        timezone,
        initialVoucherCall,
        origin: modifyOrigin(origin),
        originParam,
      }),
    sendFeedback: ({ therapistId, data }: { therapistId: number; data: FeedbackReviewData }) =>
      api.post(`review/client/${therapistId}`, data),
    changePassword: (token: string, email: string, password: string) =>
      api.post(
        'register/change-password',
        { password, email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    cancelPrebookedAppointments: (eventsId: number[]) => api.post('event/cancel-prebooked', eventsId),
    createSubscription: (data) => api.post('userSubscription', data),
    changeSubscription: (userSubscriptionId, subscriptionId) =>
      api.post(`userSubscription/change?subscriptionId=${subscriptionId}&userSubscriptionId=${userSubscriptionId}`),
    askForClientInvoices: (data) => api.post('client-invoices/fetch?isAsyncDownloadRequest=true', data),
    saveTherapist: (data: {}, config) => api.post('therapist', data, config),
    changeEmail: (email: string) => api.post('app-user/change-email', { email }),
    changeEventDatetime: (eventId: number, data: { newStartDate: string; note?: string }) =>
      api.post(`event/change-datetime/${eventId}`, data),
    userExists: (email: string) => api.post('therapist/check-email', { email }),
    elaborateFeedback: (feedbackId: string, data) => api.post(`/vibe/elaborate/${feedbackId}`, data),
    addCompanyContact: (companyId: number, data: CompanyContact) => api.post(`company/${companyId}/contact`, data),
    updateCounsellingSession: (sessionId: number, data: Partial<CounsellingSessionType>) =>
      api.put(`counselling/${sessionId}`, data),
    addInsuranceCompanyId: (data) => api.post('insurance/therapist', data),
    saveSurveyAnswer: (userId: number, token: string, data: SurveyAnswerType) =>
      api.post(`/survey/${userId}/${token}/answer`, data),
    buyMembership: (membershipPlanId: number) => api.post(`/membership/order`, { membershipPlanId }),
    trackExperiment: (experimentId: string, experimentVersion: string) =>
      api.post(`/experiment`, { experimentId, experimentVersion }),
    setAutomaticMessage: (messageType: AUTOMATIC_MESSAGE_TYPES, active: boolean, message?: string) =>
      api.post(`therapist/automatic-message/${messageType}`, {
        message,
        active,
      }),
    createConversation: (therapistId: number) => api.post(`/messages/create-conversation`, { therapistId }),
    setUserActivity: (type: number) => api.post(`/app-user/activity`, { type }),

    // put/patch
    editSchedule: (scheduleId: number, scheduleData: ScheduleType) =>
      api.put('schedule', { ...scheduleData, id: scheduleId }),
    updateUserData: (data: {}) => api.put('app-user', data),
    updateTherapistData: (data: {}) => api.put(`therapist`, data),
    updateFeedback: ({ reviewId, data }: { reviewId: number; data: FeedbackReviewData }) =>
      api.patch(`review/client/${reviewId}`, data),
    updateCompanyContact: (companyId: number, contactId: number, data: CompanyContact) =>
      api.put(`/company/${companyId}/contact/${contactId}`, data),
    sendSurveyData: (userId: number, token: string, data: SurveyFinishedType | SelectedTherapistType) =>
      api.put(`/survey/${userId}/${token}`, data),
    verifyEmployeeEmail: (slug: string, email: string) => {
      return api.post(`company/${slug}/employee`, {
        companyEmail: email,
      })
    },
    confirmCompany: (companyEmployee) => api.post(`company/employee/confirm`, companyEmployee),
    verifyEmployeeCode: (slug: string, email: string) => {
      return api.post(`company/${slug}/employee`, {
        code: email,
      })
    },
    updateVibeSettings: (companyId: number, settings: Partial<VibeSettingsType>) => {
      return api.patch(`vibe/settings/${companyId}`, settings)
    },
    saveDiagnosis: (data: {}) => api.put('diagnosis', data),
    connectAccount: (feedbackId: string, uuid: string) => {
      return api.post(`/vibe/connect-account/${feedbackId}`, {
        uuid,
      })
    },
    updateSmartBillData: (data: any) => api.put('/therapist/smartbill', data),
    updateCallQualityReview: (eventId: number, data: any) => api.put(`/event/${eventId}/call-quality-review`, data),

    // delete
    deleteSchedule: (scheduleId: number) => api.delete(`schedule/${scheduleId}`),
    cancelAppointment: (appointmentId: number) => api.delete(`event/cancel/${appointmentId}`),
    deleteSubscription: (userSubscriptionId: number) => api.delete(`userSubscription/${userSubscriptionId}`),
    deleteInsuranceCompanyId: (insuranceId: number) => api.delete(`insurance/therapist/${insuranceId}`),
    cancelMembership: (membershipId: number) => api.delete(`membership/${membershipId}`),
    deleteFromNewsletter: () => api.delete(`sendpulse`),
    deleteConversation: (therapistUserId: number) =>
      api.delete(`messages`, { data: { therapistUserId: therapistUserId } }),

    // helpers
    setHeader: (key: string, value: string) => setHeader(key, value),
    setAuthorization: (auth: string) => {
      setHeader('Authorization', auth)
    },

    // head
    headClientInvoices: (url: string) => api.head(`client-invoices/download/${url}`),

    connect,
    refresh: undefined,
    headers: api.defaults.headers.common,
  }

  apiEndpoints.refresh = refresh(apiEndpoints.refreshToken)

  api.interceptors.response.use(
    (response) => response,
    (err) => {
      const error = err.response
      if (
        error &&
        error.status === 401 &&
        error.config &&
        !err.config.__isRetryRequest &&
        error.config.url !== 'oauth/refresh-token'
      ) {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)

        let jwtToken
        try {
          jwtToken = jwtDecode(refreshToken)
        } catch {
          handleLogoutWithHeaderReset()
        }

        if (jwtToken && jwtToken.exp > +new Date() / 1000) {
          error.config.__isRetryRequest = true
          return apiEndpoints
            .refreshToken(refreshToken)
            .then((response) => {
              if (response.status === 200) {
                localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken)
                setHeader('Authorization', `Bearer ${response.data.accessToken}`)
                return Promise.resolve(
                  api({
                    ...error.config,
                    headers: {
                      ...error.config.headers,
                      Authorization: `Bearer ${response.data.accessToken}`,
                    },
                  }),
                )
              }
              handleLogoutWithHeaderReset()
            })
            .catch(() => {
              if (notServer) {
                handleLogoutWithHeaderReset()
              }
            })
        }
        if (notServer) {
          handleLogoutWithHeaderReset()
        }
        error.config.__isRetryRequest = false
      }
      if (error && error.status === 503 && !err.config.__isRetryRequest && error.data.message === 'Not found') {
        // api.dispatch(UserActions.loginFailure())
      } else if (error && error.status === 503 && !err.config.__isRetryRequest) {
        handleLogoutWithHeaderReset()
      }
      return Promise.reject(error)
    },
  )

  return apiEndpoints
}

export const rawEndpoints = endpoints
export type ApiEndpoints = ReturnType<typeof endpoints>

export default endpoints(axiosApi)
