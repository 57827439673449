import { useGrowthBook } from '@growthbook/growthbook-react'
import React, { useEffect } from 'react'
import useUser from '../hooks/useUser'
import useCompanyBenefits from '../hooks/useCompanyBenefits'
import { clearExperimentSessionData, getExperimentSessionData, trackExperiment } from '@/src/utilities/growthBookUtils'
import { useApis } from '@/src/contexts/api-context'

const GrowthBookAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const gb = useGrowthBook()
  const { user } = useUser()
  const { company } = useCompanyBenefits()
  const { api } = useApis()

  const userId = user?.id
  const companyId = company?.id

  useEffect(() => {
    if (userId && gb) {
      gb.setAttributes({
        ...gb.getAttributes(),
        id: userId,
        loggedIn: true,
      })
    }
  }, [userId, gb])

  useEffect(() => {
    if (companyId) {
      gb.setAttributes({ ...gb.getAttributes(), companyId })
    }
  }, [companyId])

  useEffect(() => {
    const sessionData = getExperimentSessionData()
    if (sessionData) {
      for (const experimentData of sessionData) {
        trackExperiment(api, experimentData.experimentId, experimentData.experimentVersion)
      }
      clearExperimentSessionData()
    }
  }, [])

  return <>{children}</>
}

export default GrowthBookAuth
