import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

/*
 * This is problematic issue with persisting scroll state. Next.js can't handle some situations,
 * e.g. when rendering of long lists.
 *
 * See https://github.com/vercel/next.js/issues/3303
 * */

const useScrollPosition = () => {
  const router = useRouter()

  const scrollCache = useRef<Record<string, [number, number]>>({})
  const activeRestorePath = useRef<string>()

  useEffect(() => {
    if (history.scrollRestoration !== 'manual') {
      history.scrollRestoration = 'manual'
    }
    const getCurrentPath = () => location.pathname + location.search
    router.beforePopState(() => {
      activeRestorePath.current = getCurrentPath()
      return true
    })
    const onComplete = () => {
      const scrollPath = activeRestorePath.current
      if (!scrollPath || !(scrollPath in scrollCache.current)) {
        return
      }

      activeRestorePath.current = undefined
      const [scrollX, scrollY] = scrollCache.current[scrollPath]
      window.scrollTo(scrollX, scrollY)
      // sometimes rendering the page can take a bit longer
      const delay = 160
      const checkAndScroll = () => {
        if ((window.scrollX === scrollX && window.scrollY === scrollY) || scrollPath !== getCurrentPath()) {
          return
        }
        window.scrollTo(scrollX, scrollY)
      }
      setTimeout(checkAndScroll, delay)
    }
    const onScroll = () => {
      scrollCache.current[getCurrentPath()] = [window.scrollX, window.scrollY]
    }
    router.events.on('routeChangeComplete', onComplete)
    window.addEventListener('scroll', onScroll)
    return () => {
      router.events.off('routeChangeComplete', onComplete)
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
}

export default useScrollPosition
