const getGa4PriceInEur = (price: string, currency: string) => (+price * CURRENCY_CONVERSION_TO_EUR[currency]).toFixed(2)

// this is only orientation only
// it doesn't need to be up-to-date, but should be in sync with BE
enum CURRENCY_CONVERSION_TO_EUR {
  CZK = 0.042,
  HUF = 0.0028,
  NOK = 0.097,
  PLN = 0.22,
  SEK = 0.097,
  UAH = 0.031,
  GPB = 1.17,
  BGN = 0.51,
  RON = 0.21,
  CHF = 0.92,
  EUR = 1,
}

export default getGa4PriceInEur
