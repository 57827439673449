// see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal for details
export const abortSignalInMs = (ms: number): AbortSignal => {
  // As of writing this, AbortSignal.timeout() has only 88.46 % global support,
  // so we use it where possible and fallback if the support is missing.
  // https://caniuse.com/mdn-api_abortsignal_timeout_static
  if ('timeout' in AbortSignal) {
    return AbortSignal.timeout(ms)
  }

  const controller = new AbortController()
  setTimeout(() => controller.abort(), ms)
  // With the current implementation, in an ideal case,
  // we would clear the timeout once the file is downloaded,
  // in .finally() or something, but that would be too verbose,
  // so we leave it as it is.

  return controller.signal
}
