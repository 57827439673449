import { createActions, createReducer } from 'reduxsauce'
import thunk from 'redux-thunk'
import { assocPath, mergeDeepRight } from 'ramda'
import FetchingMiddleware from '../services/FetchingMiddleware'
import { createPrebookingAppointments } from '../utilities/createPrebookingAppointments'
import type { PrebookingAppointmentType, TherapistStateType } from '../types/TherapistStateType'
import type { ServiceType } from '../types/GeneralStateType'

/* ------------- Enums ------------- */
export const PREBOOKING_MODE = {
  OFF: 0,
  SELECT_MODE: 1,
  PRE_SAVE_MODE: 2,
}

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  onChangeTherapistValue: ['path', 'value'],
  onMergeTherapistValue: ['value'],
  getAllClientsRequest: null,
  addScheduleRequest: ['data'],
  editScheduleRequest: ['id', 'data'],
  deleteScheduleRequest: ['id'],
  getScheduleRequest: null,
  getScheduleAsClientRequest: ['therapistId', 'dateTo', 'serviceId'],
  getTherapistDataRequest: ['therapistUserId'],
  getTherapistCalendarToken: ['therapistId'],
  updateTherapistDataRequest: ['data'],
  updatePrebookingAppointment: ['appointment', 'service'],
  reservePrebookingAppointmentRequest: ['appointments', 'emailNote', 'videoChat'],
  setCalendarPrebookingMode: ['prebookingMode'],
})

// ======================================================
// Middleware Configuration
// ======================================================
const middleware = [thunk]

middleware.push(FetchingMiddleware as any)

export const TherapistTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  reviews: [],
  clients: [],
  schedule: [],
  appointments: [],
  prebookingAppointments: [],
  prebookingMode: PREBOOKING_MODE.OFF,
}

/* ------------- Reducers ------------- */

export const onChangeTherapistValueR = (
  state: TherapistStateType,
  { path, value }: { path: string; value: Object | string | boolean | number },
): TherapistStateType => assocPath(path.split('.'), value, state)

export const onMergeTherapistValueR = (
  state: TherapistStateType,
  { value }: { value: Object | boolean | number | string },
): TherapistStateType => mergeDeepRight(state, value)

export const updatePrebookingAppointmentR = (
  state: TherapistStateType,
  { appointment, service }: { appointment: PrebookingAppointmentType; service: ServiceType },
): TherapistStateType => createPrebookingAppointments(state, appointment, service)

export const setCalendarPrebookingModeR = (
  state: TherapistStateType,
  { prebookingMode }: { prebookingMode: number },
): TherapistStateType => assocPath(['prebookingMode'], prebookingMode, state)

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_CHANGE_THERAPIST_VALUE]: onChangeTherapistValueR,
  [Types.ON_MERGE_THERAPIST_VALUE]: onMergeTherapistValueR,
  [Types.GET_ALL_CLIENTS_REQUEST]: (state) => state,
  [Types.ADD_SCHEDULE_REQUEST]: (state) => state,
  [Types.EDIT_SCHEDULE_REQUEST]: (state) => state,
  [Types.DELETE_SCHEDULE_REQUEST]: (state) => state,
  [Types.GET_SCHEDULE_REQUEST]: (state) => state,
  [Types.GET_THERAPIST_DATA_REQUEST]: (state) => state,
  [Types.UPDATE_THERAPIST_DATA_REQUEST]: (state) => state,
  [Types.UPDATE_PREBOOKING_APPOINTMENT]: updatePrebookingAppointmentR,
  [Types.RESERVE_PREBOOKING_APPOINTMENT_REQUEST]: (state) => state,
  [Types.GET_SCHEDULE_AS_CLIENT_REQUEST]: (state) => state,
  [Types.SET_CALENDAR_PREBOOKING_MODE]: setCalendarPrebookingModeR,
} as any)
