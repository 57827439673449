import { find, pathOr, propEq } from 'ramda'
import type { ReduxState } from '../store/reducers'
import type { TherapistType } from '../types/TherapistStateType'
import getGa4PriceInEur from './getGa4PriceInEur'
import { hashText } from '@/src/utilities/hash'
import getCustomerType from '@/src/utilities/isB2C'

type UserProps = {
  email: string
  phone?: string
}
const getUserParams = async (user: UserProps) => {
  const hashEmail = user ? await hashText(user.email) : undefined
  const hashPhone = user.phone ? await hashText(user.phone) : undefined
  return {
    email: user.email,
    phone: user.phone ?? undefined,
    emailSHA256: hashEmail,
    phoneSHA256: hashPhone,
  }
}

const eventDefinitionsMap = (rAction: Record<string, any>) => {
  switch (rAction.type) {
    case 'ADD_GTM_SUCCESS_PAYMENT_EVENT':
      return paymentSuccessEvent
    case 'ADD_GTM_SUCCESS_VOUCHER_EVENT':
      return voucherSuccessEvent
    default:
      break
  }
}

const currencyCode = process.env.NEXT_PUBLIC_CURRENCY

const paymentSuccessEvent = async (action: Record<string, any>, state$: ReduxState) => {
  const { appointmentId, lastPayedAppointment, identifier, user } = action

  const serviceId = lastPayedAppointment.serviceId
  const priceObj = lastPayedAppointment.therapist?.prices?.find((item) => item.serviceId === serviceId)
  const category = pathOr('Individuální terapie', ['name'], find(propEq(serviceId, 'id'))(state$.general.services))
  const isFirstBoughtEvent = lastPayedAppointment.isFirstBoughtEvent
  const isB2B = getCustomerType(lastPayedAppointment).isB2B
  const isB2C = getCustomerType(lastPayedAppointment).isB2C

  const priceString = priceObj?.price ? `${priceObj.price}` : '0'
  const priceInEur = getGa4PriceInEur(priceString, currencyCode)
  const item_id = `ID${lastPayedAppointment.therapist.therapistId}`
  const name = `${lastPayedAppointment.therapist.titleBefore} ${lastPayedAppointment.therapist.firstName} ${lastPayedAppointment.therapist.lastName} ${lastPayedAppointment.therapist.titleAfter}`
  const uniqueId = `EVENT-${appointmentId}-${identifier}`

  // spec according to https://docs.google.com/document/d/1GNZ0kINV2GaNjrqTV9z3iSQe0pTgqziiLnIy_HsAKck/edit
  const ga4Event = {
    ecommerce: {
      transaction_id: uniqueId,
      value: priceInEur,
      currency: 'EUR',
      traffic_type: '',
      customer_type: isFirstBoughtEvent ? 'acquisition' : isB2B ? 'b2b' : 'retention',
      items: [
        {
          item_id,
          item_variant: lastPayedAppointment.serviceId.toString(),
          item_name: name,
          item_category: category,
          price: priceInEur,
          quantity: 1,
        },
      ],
    },
    user: {
      ...(await getUserParams(user)),
      ...(isB2C ? { id: user.userId, loggedIn: !!user.userId } : {}),
    },
  }

  const legacyEvent = {
    event: 'eec.purchase',
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: uniqueId,
          affiliation: 'Hedepy',
          revenue: priceString,
          coupon: lastPayedAppointment.voucherCode ? lastPayedAppointment.voucherCode : '',
        },
        products: [
          {
            id: item_id,
            name,
            category,
            quantity: 1,
            price: priceString,
            dimension5: isFirstBoughtEvent ? 'New' : 'Repeated',
          },
        ],
      },
    },
    user: await getUserParams(user),
  }

  return [
    legacyEvent,
    ...(isB2C ? [{ ...ga4Event, event: 'purchase_total' }] : []),
    ...(isB2C && isFirstBoughtEvent ? [{ ...ga4Event, event: 'purchaseAcq' }] : []),
  ]
}

const voucherSuccessEvent = (action: Record<string, any>, state$: ReduxState) => {
  const { therapistId, appointments } = action
  const therapist = state$.client.therapists.find((item: TherapistType) => item.therapistId === therapistId)
  const appointment = appointments[0]
  const therapistService = therapist.prices && therapist.prices.find((item) => item.serviceId === appointment.serviceId)
  let category = 'Individuální terapie'
  if (therapistService) {
    category = pathOr('', ['name'], find(propEq(therapistService.serviceId, 'id'))(state$.general.services))
  }
  if (appointment) {
    return {
      event: 'eec.purchase',
      ecommerce: {
        currencyCode,
        purchase: {
          actionField: {
            id: `EVENT-${appointment.id}-VOUCHER-${appointment.voucherCode}`,
            affiliation: 'Hedepy',
            revenue: therapistService && therapistService.price,
            coupon: appointment.voucherCode,
          },
          products: [
            {
              id: `ID${therapist.therapistId}`,
              name: `${therapist.titleBefore} ${therapist.firstName} ${therapist.lastName} ${therapist.titleAfter}`,
              category,
              quantity: appointments.length,
              price: therapistService && therapistService.price ? `${therapistService.price}` : '0',
              dimension5: appointment.isFirstBoughtEvent ? 'New' : 'Repeated',
            },
          ],
        },
      },
    }
  }
}
export default eventDefinitionsMap
