import { useApis } from '@/src/contexts/api-context'
import useSWRImmutable from 'swr/immutable'
import { UserActivity } from './types'

const fetchUserActivity = async (api): Promise<UserActivity[]> => {
  const res = await api.getUserActivity()
  return res.data
}

const useUserActivity = () => {
  const { api } = useApis()
  const {
    data,
    mutate: refetchActivities,
    isLoading,
  } = useSWRImmutable(['useUserActivity'], () => fetchUserActivity(api))

  return { data, refetchActivities, isLoading }
}

export default useUserActivity
