import { AppointmentType } from '@/src/types/TherapistStateType'

const getCustomerType = (appointment: AppointmentType) => {
  const isB2B = appointment.voucherType === 'b2b' || Boolean(appointment.companyEventQuotaId)
  return {
    isB2B,
    isB2C: !isB2B,
  }
}

export default getCustomerType
