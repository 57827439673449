import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createEpicMiddleware } from 'redux-observable'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { createMiddleware } from 'redux-beacon'
import FetchingMiddleware from '../services/FetchingMiddleware'
import GTMMiddleware from '../analytics/GTMMiddleware'

import makeRootReducer from './reducers'

const gtm = GoogleTagManager()

const makeStore = (initialState: Record<string, any> = {}, { isServer }: { isServer: boolean }, apis, rootEpic) => {
  const epicMiddleware = createEpicMiddleware()

  const composeEnhancers =
    (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const middleware = [thunk, FetchingMiddleware, epicMiddleware]
  const enhancers: [] = []

  middleware.push(createMiddleware(GTMMiddleware, gtm))

  const store = createStore(
    // @ts-expect-error redux will never be typed correctly. We will ditch it
    makeRootReducer(isServer),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  )

  apis.connect(store.getState, store.dispatch)

  // @ts-expect-error redux will never be typed correctly. We will ditch it
  store.asyncReducers = {}

  epicMiddleware.run(rootEpic)

  return store
}

export default makeStore
