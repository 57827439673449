import { hashText } from '@/src/utilities/hash'
import { TherapistType } from '@/src/types/TherapistStateType'
import { PUBLIC_THERAPISTS_PAGE_SLUG } from '@/src/constants/locale.constants'

export const GTM_ID = 'GTM-P59J4V5'

function ga(argument) {
  const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || [])
  dataLayer.push(argument)
}

export const pageview = (url, user: { id: number }) => {
  ga({
    hitType: 'pageview',
    event: 'pageview',
    page: url,
    user: {
      id: user.id,
      loggedIn: !!user.id,
    },
  })
}

type UserProps = {
  email?: string
  phone?: string
  isLogged?: boolean
}

export const addGtmEvent = async (event: string, destination: string = 'without destination', user?: UserProps) => {
  const hashEmail = user ? await hashText(user.email) : undefined
  const hashPhone = user && user.phone ? await hashText(user.phone) : undefined
  ga({
    event,
    destination,
    ...(user
      ? {
          user: {
            email: user.email,
            phone: user.phone ?? undefined,
            emailSHA256: hashEmail,
            phoneSHA256: hashPhone,
            loggedIn: user.isLogged,
          },
        }
      : {}),
  })
}

export const addGtmProductEvent = (
  event: string,
  destination: string,
  therapist: TherapistType & {
    service: { id: number; price: number }
    date?: string
    time?: string
  },
  isPublic: boolean,
) => {
  ga({
    event,
    ecommerce: {
      [`${destination}`]: {
        products: [
          {
            id: therapist.therapistId,
            name: `${therapist.firstName} ${therapist.lastName}`,
            url: isPublic ? `/${PUBLIC_THERAPISTS_PAGE_SLUG}/${therapist.slug}` : `/client/${therapist.id}/profile`,
            price: therapist.price,
            brand: `hedepy.${process.env.NEXT_PUBLIC_COUNTRY_IDENTIFIER}}`,
            category: therapist.service.id,
            variant: 'new',
            quantity: 1,
            currency: process.env.NEXT_PUBLIC_CURRENCY,
            date: therapist.date ? therapist.date : undefined,
            time: therapist.time ? therapist.time : undefined,
          },
        ],
      },
    },
  })
}

export const addGtmUserInAppEvent = async (credentials, userType) => {
  const hash = await hashText(credentials.email)
  const hashPhone = credentials.phone ? await hashText(credentials.phone) : undefined
  const loginSuccess = credentials.event === 'loginSuccessful'
  ga({
    event: credentials.event,
    destination: loginSuccess ? userType : credentials.destination,
    user: {
      id: loginSuccess ? credentials.id : undefined,
      loggedIn: loginSuccess ? true : undefined,
      email: credentials.email,
      phone: credentials.phone ?? undefined,
      emailSHA256: hash,
      phoneSHA256: hashPhone,
    },
  })
}
