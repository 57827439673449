import React from 'react'
import dynamic from 'next/dynamic'
import GrowthBookAuth from './GrowthBookAuth'

const MessagesProvider = dynamic(
  () => import('../contexts/messages-context/messages-context').then((file) => file.MessagesProvider),
  {
    ssr: false,
  },
)

const ProvidersAuthenticated: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <MessagesProvider>
      <GrowthBookAuth>{children}</GrowthBookAuth>
    </MessagesProvider>
  )
}

export default ProvidersAuthenticated
