import { ApiEndpoints, createAxiosApi, rawEndpoints } from '../services/api'
import { createAxiosApi as createNextApi, rawEndpoints as rawNextEndpoints } from '../services/nextApi'

import RootEpic from '../epics'

export type ApisType = {
  api: ApiEndpoints
  next: any
  setToken: (name: string, token: string) => void
  connect: (getState: () => void, dispatch: () => void) => void
}

const apisConnected = () => {
  const api = rawEndpoints(createAxiosApi())
  const next = rawNextEndpoints(createNextApi())
  const apis = {
    api,
    next,
    connect: (getState: () => {}, dispatch: () => {}) => {
      apis.api.connect(getState, dispatch)
    },
  } as ApisType

  const rootEpic = RootEpic(apis)
  return [apis, rootEpic]
}

export default apisConnected
