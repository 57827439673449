import dayjs from 'dayjs'
import { GroupsOfEvents, UpcomingAppointmentsType } from './types'
import { AppointmentType } from '@/src/types/TherapistStateType'
import { APPOINTMENT_STATUS } from '@/src/redux/ClientRedux'

export const groupAllEvents = (allEvents: AppointmentType[]): GroupsOfEvents => {
  const futureEvents: AppointmentType[] = []
  const pastEvents: AppointmentType[] = []
  const otherEvents: AppointmentType[] = []

  allEvents?.forEach((event) => {
    if (dayjs(event.endDate).isAfter(dayjs()) && event.status === APPOINTMENT_STATUS.RESERVED) {
      futureEvents.push(event)
    } else if (event.status === APPOINTMENT_STATUS.COMPLETED) {
      pastEvents.push(event)
    } else if (![APPOINTMENT_STATUS.PREBOOKING, APPOINTMENT_STATUS.REFUSED].includes(event.status)) {
      otherEvents.push(event)
    }
  })

  const overviewEvents = pastEvents
    .concat(otherEvents)
    .sort((a: AppointmentType, b: AppointmentType) => (dayjs(a.startDate).isBefore(b.startDate) ? 1 : -1))
  return { futureEvents, pastEvents, overviewEvents }
}

export const processUpcomingEvents = (appointments: AppointmentType[]): UpcomingAppointmentsType => {
  const processed: UpcomingAppointmentsType = { today: [], tomorrow: [], thisWeek: [], upcoming: [] }
  appointments?.forEach((appointment) => {
    const now = dayjs()
    const dayjsStartDate = dayjs(appointment.startDate)
    const dayjsEndDate = dayjs(appointment.endDate)

    if (dayjsEndDate.isAfter(now) && !appointment.isCollision) {
      if (dayjsStartDate.isSame(now, 'day')) {
        processed.today.push(appointment)
      } else if (dayjsStartDate.isSame(now.add(1, 'day'), 'day')) {
        processed.tomorrow.push(appointment)
      } else if (dayjsStartDate.isSame(now, 'week')) {
        processed.thisWeek.push(appointment)
      } else {
        processed.upcoming.push(appointment)
      }
    }
  })
  return processed
}
