import { createReducer, createActions } from 'reduxsauce'
import { assoc, assocPath } from 'ramda'
import type { ValidationStateType } from '../types/ValidationStateType'

/* ------------- Helpers ------------- */
export const VALIDATION_TYPE = {
  error: 'error',
  info: 'info',
  warning: 'warning',
}

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setValidationErrors: ['errors'],
  setValidationError: ['path', 'errors'],
  setInvalidMessage: ['message'],
})
export const ValidationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE: ValidationStateType = {
  validationErrors: {},
  invalidMessage: '',
}
/* ------------- Reducers ------------- */

const setValidationErrorsR = (state, { errors }) => assoc('validationErrors', errors, state)

const setValidationErrorR = (state, { path, errors }) =>
  assocPath(['validationErrors', ...path], errors[path.pop()], state)

const setInvalidMessageR = (state, { message }: { message: string }) => assoc('invalidMessage', message, state)

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_VALIDATION_ERRORS]: setValidationErrorsR,
  [Types.SET_VALIDATION_ERROR]: setValidationErrorR,
  [Types.SET_INVALID_MESSAGE]: setInvalidMessageR,
})
