import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useSession } from '../contexts/session-context'
import { useAppSelector } from '../store/hooks'
import { ReduxState } from '../store/reducers'
import { fetchingSelector } from '../store/selectors/GeneralSelectors'
import { redirectUnauthorized } from '../utilities/redirectUnauthorized'
import Loader from './Loader'

export type AuthType = boolean | { redirectToOriginal?: boolean; loginPage?: string }

type Props = {
  auth: AuthType
  children: React.ReactNode
}

// Simple Auth component that replaces withAuth
const Auth: React.FC<Props> = ({ children, auth }) => {
  const { user, isAuthenticating } = useSession()
  const isLoadingUser = useAppSelector((state: ReduxState) => fetchingSelector(state, 'user'))
  const isLoading = isAuthenticating || isLoadingUser
  const router = useRouter()
  const isUser = !!user.id

  let redirectUrlOverride = null
  let loginPage
  if (typeof auth !== 'boolean') {
    loginPage = auth.loginPage
    if (auth?.redirectToOriginal) {
      redirectUrlOverride = router.asPath
    }
  }

  useEffect(() => {
    if (isLoading) return // Do nothing while loading
    if (!isUser) redirectUnauthorized({ router, redirectUrlOverride, loginPage }) // If not authenticated, force log in
  }, [isUser, isLoading])

  if (isUser) {
    return <>{children}</>
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <Loader visible color="light-gray" />
}

export default Auth
