import { assocPath, pathOr } from 'ramda'
import dayjs from 'dayjs'
import { APPOINTMENT_STATUS, CELL_DURATION } from '../redux/ClientRedux'
import type { AppointmentType, PrebookingAppointmentType, TherapistStateType } from '../types/TherapistStateType'
import type { ServiceType } from '../types/GeneralStateType'

export const createPrebookingAppointments = (
  state: TherapistStateType,
  appointment: PrebookingAppointmentType,
  service: ServiceType,
) => {
  const appointmentEndDate =
    service.duration > CELL_DURATION
      ? dayjs(appointment.startDate).add(service.duration, 'minute').toISOString()
      : appointment.endDate
  const removedAppointments: AppointmentType[] = []
  const prebookingAppointments = pathOr([], ['prebookingAppointments'], state)
  const schedule = pathOr([], ['schedule'], state)
  if (!appointment.removedAppointments) {
    pathOr([], ['schedule'], state).forEach((item: AppointmentType) => {
      if (dayjs(item.endDate).subtract(1, 'minute').isBetween(appointment.startDate, appointmentEndDate)) {
        removedAppointments.push(item)
      }
    })
    if (removedAppointments.length > 0) {
      state = assocPath(
        ['schedule'],
        schedule.filter((item) => !removedAppointments.some((i) => i.id === item.id)),
        state,
      )
    }
    if (appointment.status === APPOINTMENT_STATUS.PREBOOKING) {
      state = assocPath(
        ['prebookingAppointments'],
        prebookingAppointments.filter((item) => !dayjs(item.startDate).isSame(dayjs(appointment.startDate))),
        state,
      )
    } else {
      state = assocPath(
        ['prebookingAppointments'],
        [
          ...prebookingAppointments,
          {
            ...appointment,
            status: APPOINTMENT_STATUS.PREBOOKING,
            endDate: appointmentEndDate,
            removedAppointments: removedAppointments.length > 0 ? removedAppointments : false,
          },
        ],
        state,
      )
    }
  } else {
    state = assocPath(
      ['prebookingAppointments'],
      prebookingAppointments.filter((item) => !dayjs(appointment.startDate).isSame(dayjs(item.startDate))),
      state,
    )
    state = assocPath(['schedule'], [...schedule, ...appointment.removedAppointments], state)
  }
  if (appointment.newStartDate && appointment.newEndDate) {
    state = createPrebookingAppointments(
      state,
      { startDate: appointment.newStartDate, endDate: appointment.newEndDate },
      service,
    )
  }
  return state
}

export default { createPrebookingAppointments }
