import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'
import { from } from 'rxjs'
import Router from 'next/router'
import * as Sentry from '@sentry/nextjs'
import FileSaver from 'file-saver'
import uniqBy from 'lodash/uniqBy'

import { generalErrorHandler, generalSuccessHandler } from './EpicHelpers'
import GeneralActions from '../redux/GeneralRedux'
import UserActions, { USER_TYPE, UserTypes } from '../redux/UserRedux'
import TherapistActions from '../redux/TherapistRedux'
import ClientActions from '../redux/ClientRedux'
import DiagnosisActions from '../redux/DiagnosisRedux'
import { convertArrayToObject, convertArrayToObjectNoDuplicateKey } from '../utilities/arrayTobject'
import Toaster from '../utilities/toaster'
import { withoutFetching } from '../services/FetchingMiddleware'
import type { ReduxState } from '../store/reducers'
import { addGtmEvent, addGtmUserInAppEvent } from '../analytics/gtm'
import useFlags from '../hooks/useFlags'
import { UserType } from '../types/UserStateType'
import { ApiEndpoints } from '../services/api'
import { setTokens } from '../utilities/setTokens'
import { abortSignalInMs } from '@/src/utilities/fetchUtils'
import ValidationRules from '@/src/utilities/validationRules'
import { FbEventName, sendFbEventByName } from '@/src/analytics/fbPixelData'

type Api = { [key: string]: any }

const hasUserCompleteAddress = (user: UserType, showPlaceCategoryInAddress: boolean) => {
  const hasDefaultAddressFields =
    user.firstName && user.lastName && user.street && user.houseNumber && user.city && user.zip && user.country

  return !!(showPlaceCategoryInAddress ? hasDefaultAddressFields && user.publicPlaceCategory : hasDefaultAddressFields)
}

const processSuccessfulLogin = (
  data: any,
  apis: any,
  therapistId: any,
  isDiagnosis: boolean,
  diagnosisData?: any,
  redirectUrl?: string,
  sendEvent = true,
) => {
  setTokens({
    api: apis.api,
    data: {
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
    },
  })
  return from([
    GeneralActions.onChangeGeneralValue('emailIsInOtherCountry', !!data.emailInOtherCountry),
    UserActions.getUserDataRequest(
      therapistId,
      isDiagnosis,
      sendEvent
        ? {
            event: 'loginSuccessful',
          }
        : {},
      diagnosisData,
      redirectUrl,
    ),
  ])
}

export default class UserEpic {
  static getAllAppointments = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.GET_ALL_APPOINTMENTS_REQUEST),
      mergeMap(({ userType }) =>
        from(userType === USER_TYPE.THERAPIST ? api.getCurrentAppointments() : api.getAllAppointments()).pipe(
          mergeMap((response: any) => {
            const filteredData = uniqBy(response.data, 'id')
            const actions: AnyAction[] = []
            if (userType === USER_TYPE.THERAPIST) {
              actions.push(TherapistActions.onChangeTherapistValue('appointments', filteredData))
            } else {
              actions.push(
                ClientActions.onChangeClientValue('appointments', convertArrayToObject(filteredData, 'therapistId')),
              )
            }
            actions.push(generalSuccessHandler('', 'allAppointments'))
            return from(actions)
          }),
          catchError(generalErrorHandler('allAppointments')),
        ),
      ),
    )

  static updateUserData = (api: ApiEndpoints) => (action$: any) => {
    const { showPlaceCategoryInAddress } = useFlags()

    return action$.pipe(
      ofType(UserTypes.UPDATE_USER_DATA_REQUEST),
      switchMap(({ data }) =>
        from(api.updateUserData(data)).pipe(
          mergeMap(() => {
            return from([
              generalSuccessHandler('component.toaster.userDataUpdateSuccess', 'updateUserData'),
              UserActions.onMergeUserValue({
                ...data,
                hasCompleteAddress: hasUserCompleteAddress(data, showPlaceCategoryInAddress),
              }),
              //
            ])
          }),
          catchError(generalErrorHandler('updateUserData')),
        ),
      ),
    )
  }
  static register = (apis: Api) => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.REGISTER_REQUEST),
      switchMap(({ data, therapistId, diagnosis, redirectUrl }) =>
        from(apis.next.register(data)).pipe(
          mergeMap((response: any) => {
            setTokens({
              api: apis.api,
              data: {
                refreshToken: response.data.refreshToken,
                accessToken: response.data.accessToken,
              },
            })
            Sentry.setUser({ email: data.email })
            Toaster.success('component.toaster.registrationSuccess')
            setTokens({
              api: apis.api,
              data: { refreshToken: response.data.refreshToken, accessToken: response.data.accessToken },
            })
            return from([
              UserActions.getUserDataRequest(
                therapistId,
                diagnosis,
                {
                  event: 'registrationFinished',
                  destination: diagnosis ? 'diagnoseView' : 'dashboardView',
                },
                undefined,
                redirectUrl,
              ),
            ])
          }),
          catchError(generalErrorHandler('authorization')),
        ),
      ),
    )

  static login = (apis: Api) => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.LOGIN_REQUEST),
      switchMap(({ email, password, therapistId, diagnosis, redirectUrl, companyEmployee }) =>
        from(apis.next.login(email, password, companyEmployee)).pipe(
          mergeMap((response: any) => {
            Sentry.setUser({ email })
            return processSuccessfulLogin(response.data, apis, therapistId, diagnosis, undefined, redirectUrl)
          }),
          catchError((error) =>
            generalErrorHandler('authorization', [
              GeneralActions.onChangeGeneralValue(
                'emailIsInOtherCountry',
                !!error.response?.data?.emailInOtherCountry || false,
              ),
            ])(error),
          ),
        ),
      ),
    )
  static loginFacebook = (apis: Api) => (action$: any, state: any) =>
    action$.pipe(
      ofType(UserTypes.LOGIN_REQUEST_FACEBOOK),
      switchMap(({ code, therapistId, diagnosis, redirectUrl, companyEmployee, doNotCreateNewUser }) =>
        from(apis.next.loginFacebook(code, companyEmployee, doNotCreateNewUser)).pipe(
          mergeMap((response: any) => {
            Sentry.setUser({ email: state.value.user.email })
            const isRegistration = response.status === 201
            if (isRegistration) {
              addGtmEvent('registrationFinished', diagnosis ? 'diagnoseView' : 'dashboardView', {
                email: state.value.user.email,
              })
              sendFbEventByName({
                eventName: FbEventName.CompleteRegistration,
                user: { email: state.value.user.email },
              })
              Toaster.success('component.toaster.registrationSuccess')
            }
            return processSuccessfulLogin(
              response.data,
              apis,
              therapistId,
              !!diagnosis,
              diagnosis,
              redirectUrl,
              !isRegistration,
            )
          }),
          catchError((error) => {
            const response = error.response?.data
            if (response.type === 'NOT_FOUND') {
              Router.push({
                pathname: '/login',
                query: { ...Router.query, socialUser: response.socialUser, kind: 'fb' },
              })
            } else if (response.type === 'NO_SUCH_VERIFICATION') {
              Router.push('/login')
            } else {
              Toaster.error('errors.defaultError')
              Router.push('/login')
            }
            return generalErrorHandler('authorization')(error)
          }),
        ),
      ),
    )
  static loginGoogle = (apis: Api) => (action$: any, state: any) =>
    action$.pipe(
      ofType(UserTypes.LOGIN_REQUEST_GOOGLE),
      switchMap(({ idToken, therapistId, diagnosis, redirectUrl, doNotCreateNewUser, companyEmployee }) => {
        return from(apis.next.loginGoogle(idToken, companyEmployee, doNotCreateNewUser)).pipe(
          mergeMap((response: any) => {
            Sentry.setUser({ email: state.value.user.email })
            const isRegistration = response.status === 201
            if (isRegistration) {
              addGtmEvent('registrationFinished', diagnosis ? 'diagnoseView' : 'dashboardView', {
                email: state.value.user.email,
              })
              sendFbEventByName({
                eventName: FbEventName.CompleteRegistration,
                user: { email: state.value.user.email },
              })
              Toaster.success('component.toaster.registrationSuccess')
            }
            return processSuccessfulLogin(
              response.data,
              apis,
              therapistId,
              diagnosis,
              undefined,
              redirectUrl,
              !isRegistration,
            )
          }),
          catchError((error) => {
            const response = error.response?.data
            if (response.type === 'NOT_FOUND') {
              Router.push({ query: { ...Router.query, socialUser: response.socialUser, kind: 'google' } })
            } else if (response.type === 'NO_SUCH_VERIFICATION') {
              Router.push('/login')
            } else {
              Toaster.error('errors.defaultError')
              Router.push('/login')
            }
            return generalErrorHandler('authorization')(error)
          }),
        )
      }),
    )
  static getUserData = (api: ApiEndpoints) => (action$: any, _state$: { value: ReduxState }) => {
    const { showPlaceCategoryInAddress } = useFlags()

    return action$.pipe(
      ofType(UserTypes.GET_USER_DATA_REQUEST),
      switchMap(({ therapistId, withoutRedirect, gtm, diagnosisData, redirectUrl }) =>
        from(api.getUserData()).pipe(
          mergeMap((response: any) => {
            const user = {
              ...response.data,
              hasCompleteAddress: hasUserCompleteAddress(response.data, showPlaceCategoryInAddress),
            }

            if (typeof window !== 'undefined') {
              let url = ['/therapist/appointments', '/therapist/appointments']
              const { startDate, endDate, service } = Router.query

              if (user.type === USER_TYPE.CLIENT) {
                if (typeof therapistId === 'number' && startDate && endDate && service) {
                  url = ['/client/[therapist]/profile', `/client/${therapistId}/profile`]
                  gtm.destination = 'profileView'
                } else if (typeof therapistId === 'number' && !Number.isNaN(therapistId)) {
                  url = ['/client/[therapist]/profile', `/client/${therapistId}/profile`]
                } else {
                  url = ['/client/calendar', '/client/calendar']
                }
              }

              if (!withoutRedirect) {
                const isSafeRedirectUrl = redirectUrl && ValidationRules.isSafeRedirectUrl(redirectUrl)
                if (redirectUrl && !isSafeRedirectUrl) {
                  Sentry.captureMessage(`Attempt at unsafe redirect URL: ${redirectUrl}`)
                }

                if (isSafeRedirectUrl) {
                  Router.push(redirectUrl)
                } else {
                  Router.push(
                    { pathname: url[0], query: Router.query },
                    { pathname: url[1], query: Router.query },
                  ).catch((err) => {
                    Sentry.captureException(err)
                  })
                }
              }
            } else {
              Sentry.captureException(new Error('No window after getUserData!'))
            }

            if (gtm?.event) {
              addGtmUserInAppEvent(
                {
                  ...gtm,
                  email: user.email,
                  phone: user.phone,
                  id: user.id,
                },
                user.type === USER_TYPE.THERAPIST ? 'therapist' : 'client',
              )
            }

            const actions = [
              GeneralActions.onStopFetching(['authorization']),
              UserActions.onMergeUserValue(user),
              DiagnosisActions.getDiagnosisRequest(diagnosisData),
            ]
            if (user.type === USER_TYPE.THERAPIST) {
              actions.push(
                TherapistActions.getScheduleRequest(),
                TherapistActions.getAllClientsRequest(),
                UserActions.getAllAppointmentsRequest(user.type),
                TherapistActions.getTherapistDataRequest(user.id),
              )
            } else {
              actions.push(
                ClientActions.getTherapistsRequest(),
                UserActions.getAllAppointmentsRequest(user.type, user.id),
              )
            }
            return from(actions)
          }),
          catchError(generalErrorHandler('authorization')),
        ),
      ),
    )
  }

  static changePassword = (api: any) => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.CHANGE_PASSWORD_REQUEST),
      switchMap(({ token, email, password }) =>
        from(api.changePassword(token, email, password)).pipe(
          mergeMap((response: any) => {
            setTokens({ api, data: response.data })
            return from([
              generalSuccessHandler('component.toaster.changePasswordSuccess', withoutFetching),
              UserActions.getUserDataRequest(null, false, {
                event: 'loginSuccessful',
              }),
            ])
          }),
          catchError(generalErrorHandler('authorization')),
        ),
      ),
    )
  static getTwilioFile = () => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.GET_TWILIO_FILE_REQUEST),
      switchMap(({ url, filename, index }) =>
        from(fetch(url, { signal: abortSignalInMs(20_000) })).pipe(
          concatMap((response) => response.blob()),
          mergeMap((blob) => {
            FileSaver.saveAs(blob, filename)
            return from([
              generalSuccessHandler(),
              UserActions.onChangeUserValue(`chat.downloadFileIndex.${index}`, false),
            ])
          }),
          catchError(generalErrorHandler()),
        ),
      ),
    )

  static getTherapistSpecializations = (api: ApiEndpoints) => (action$: any) =>
    action$.pipe(
      ofType(UserTypes.GET_THERAPIST_SPECIALIZATIONS_REQUEST),
      switchMap(() =>
        from(api.getSpecializations()).pipe(
          mergeMap((response: any) => {
            const convertedData = convertArrayToObjectNoDuplicateKey(response.data, 'id', 'name')
            return from([
              TherapistActions.onChangeTherapistValue('therapistSpecializations', convertedData),
              ClientActions.onChangeClientValue('therapistSpecializations', convertedData),
              generalSuccessHandler('', 'general'),
            ])
          }),
          catchError(generalErrorHandler()),
        ),
      ),
    )

  static getAllActions = (apis: Api) => {
    const { api } = apis
    return [
      UserEpic.getAllAppointments(api),
      UserEpic.updateUserData(api),
      UserEpic.register(apis),
      UserEpic.login(apis),
      UserEpic.loginFacebook(apis),
      UserEpic.loginGoogle(apis),
      UserEpic.getUserData(api),
      UserEpic.changePassword(api),
      UserEpic.getTherapistSpecializations(api),
      UserEpic.getTwilioFile(),
    ]
  }
}
