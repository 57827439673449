import * as Sentry from '@sentry/nextjs'
import Toaster from '../utilities/toaster'
import camelCase from 'lodash/camelCase'

const shouldSendToSentry = (error, path) => {
  // api return 404 on wrong password
  if (error?.response && error.response.status === 404 && path === 'authorization') return false

  return true
}

export const handleError = (path: string = 'general', error: any) => {
  const errorToShow = error
    ? (error.response && error.response.data && error.response.data.type) || (error.data && error.data.type)
    : false
  if (errorToShow) {
    Toaster.error(`errors.${path}.${camelCase(errorToShow)}`)
  } else {
    Toaster.error('errors.defaultError')
  }

  if (shouldSendToSentry(error, path)) {
    Sentry.captureException(error, { tags: { path, type: 'handleError' } })
  }
}
