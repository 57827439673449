import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'
import { getTranslate } from 'src/utilities/i18n'
import { isRoutePublicTherapistList } from '@/src/constants/locale.constants'

const HeadCommonTags: React.FC = () => {
  const { t } = getTranslate()
  const router = useRouter()

  const description = t('meta.description')
  const title = t('meta.title')

  return (
    <Head>
      <title>{t('headers.main')}</title>
      <meta key="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1" name="viewport" />
      {!isRoutePublicTherapistList(router.pathname) && (
        <>
          <meta content={description} name="description" />
          <meta content="website" property="og:type" />
          <meta content={`${process.env.NEXT_PUBLIC_FE_URL}`} property="og:url" />
          <meta content="summary" property="twitter:card" />
          <meta content={title} property="og:title" />
          <meta content={description} property="og:description" />
          <meta
            content="https://hedepy.cz/wp-content/uploads/2020/08/Hedepy-OpenGraph-1200x627-v02.png"
            property="og:image"
          />
          <meta content="1200" property="og:image:width" />
          <meta content="627" property="og:image:height" />
          <meta content={`${process.env.NEXT_PUBLIC_FE_URL}`} property="og:url" />
          <meta content={`${process.env.NEXT_PUBLIC_FE_URL}`} property="twitter:url" />
          <meta content={title} property="twitter:title" />
          <meta content={description} property="twitter:description" />
          <meta
            content="https://hedepy.cz/wp-content/uploads/2020/08/Hedepy-OpenGraph-1200x627-v02.png"
            property="twitter:image"
          />
          <meta content={title} name="application-name" />
        </>
      )}
    </Head>
  )
}

export default HeadCommonTags
