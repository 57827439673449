import Cookies from 'js-cookie'
import { hashText } from '@/src/utilities/hash'
import getGa4PriceInEur from '@/src/analytics/getGa4PriceInEur'
import dayjs from 'dayjs'
import { AppointmentType } from '@/src/types/TherapistStateType'
import { UserType } from '@/src/types/UserStateType'
import * as Sentry from '@sentry/nextjs'

export enum FbEventName {
  DiagnoseStartTotal = 'DiagnoseStartTotal',
  DiagnoseFinishTotal = 'DiagnoseFinishTotal',
  CompleteRegistration = 'CompleteRegistration',
  Purchase = 'Purchase',
  PageView = 'PageView',
  PurchaseAcquisition = 'PurchaseAcquisition',
}

// get rid of '0' or '+' sign at the start of the string
const normalizePhone = (phone: string) => phone.replace(/^(?:\+|0*)(\d+)$/, '$1')

const getBaseUserData = async (user) => {
  return {
    fbc: Cookies.get('_fbc'),
    fbp: Cookies.get('_fbp'),
    em: [await hashText(user.email?.toLowerCase())],
    fn: [await hashText(user.firstName?.toLowerCase())],
    ln: [await hashText(user.lastName?.toLowerCase())],
    ct: user.city ? [await hashText(user.city?.toLowerCase())] : undefined,
    zp: user.zip ? [await hashText(user.zip?.toLowerCase())] : undefined,
    country: user.country ? [await hashText(user.country?.toLowerCase())] : undefined,
    client_user_agent: window.navigator.userAgent,
    client_ip_address: user.userIp !== 'undefined' ? user.userIp : undefined,
    external_id: user.id,
  }
}

const sendEvent = async (data) => {
  try {
    const response = await fetch('/api/capi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data,
      }),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData)
    }
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtra('data', data)
      scope.setExtra('error', e)
      Sentry.captureException(e)
    })
  }
}

type PurchaseFbEventType = {
  appointment: AppointmentType
  user: UserType & { userIp: string }
  isAcquisition?: boolean
}

const sendPurchaseFbEvent = async ({ appointment, user, isAcquisition }: PurchaseFbEventType) => {
  if (process.env.NEXT_PUBLIC_ENV !== 'test') {
    const phone = user.phone ? normalizePhone(user.phone) : undefined
    const value = getGa4PriceInEur(appointment.price, process.env.NEXT_PUBLIC_CURRENCY)

    const dataToSend = [
      {
        event_name: isAcquisition ? FbEventName.PurchaseAcquisition : FbEventName.Purchase,
        event_id: `${appointment.id}`,
        event_time: dayjs().unix(),
        user_data: {
          ...(await getBaseUserData(user)),
          ph: [await hashText(phone)],
        },
        custom_data: {
          currency: 'EUR',
          value: value,
          content_type: 'product',
          contents: [
            {
              id: appointment.therapistId,
              item_price: value,
              quantity: 1,
            },
          ],
        },
        event_source_url: window.location.href,
        action_source: 'website',
      },
    ]

    if (window.fbq) {
      window.fbq(
        'track',
        isAcquisition ? FbEventName.PurchaseAcquisition : FbEventName.Purchase,
        { value, currency: 'EUR' },
        { eventID: `${appointment.id}` },
      )
    }

    sendEvent(dataToSend)
  }
}

type EventParams = {
  eventName: string
  user: any
  customData?: any
}

const sendFbEventByName = async ({ eventName, user, customData }: EventParams) => {
  if (process.env.NEXT_PUBLIC_ENV !== 'test') {
    const dataToSend = [
      {
        event_name: eventName,
        event_time: dayjs().unix(),
        user_data: await getBaseUserData(user),
        custom_data: customData,
      },
    ]

    sendEvent(dataToSend)
  }
}

const sendFbTherapistEventByName = async ({ eventName, user, therapistData }) => {
  if (process.env.NEXT_PUBLIC_ENV !== 'test') {
    const dataToSend = [
      {
        event_name: eventName,
        event_time: dayjs().unix(),
        user_data: await getBaseUserData(user),
        custom_data: {
          currency: 'EUR',
          item_name: `${therapistData.firstName} ${therapistData.lastName}`,
          item_id: therapistData.therapistId,
        },
        event_source_url: window.location.href,
        action_source: 'website',
      },
    ]

    sendEvent(dataToSend)
  }
}

export { sendPurchaseFbEvent, sendFbEventByName, sendFbTherapistEventByName }
